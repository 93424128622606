import workspace from '@/plugins/axios/workspace'
import { Features } from '@/types/features.types';

export const featuresService = {
  async fetchFeatures(workspaceId: string): Promise<Features> {
    const response = await workspace.get<{ data: Features }>(`${workspaceId}/workspaces/features`);

    return response.data.data;
  },
}
