import { defineStore } from 'pinia'

import workspaceAxios from '@/plugins/axios/workspace'
import { Product } from '@/types/product.type'
import { WorkspaceDto } from '@/types/workspace.types'
import { workspaceService } from '@/services/workspaceService'
import { useSnackbarStore } from './useSnackbarStore'

interface State {
  currentWorkspace: WorkspaceDto | undefined
  currentWorkspaceId: string | undefined
  workspaces: WorkspaceDto[]
}

export const useWorkspaceStore = defineStore('workspaceStore', {
  state: (): State => ({
    currentWorkspace: undefined,
    currentWorkspaceId: undefined,
    workspaces: []
  }),
  actions: {
    async getCurrentWorkspace(): Promise<WorkspaceDto> {
      const snackbarStore = useSnackbarStore();

      if(this.currentWorkspace) return this.currentWorkspace

      const workspaceId = await this.getWorkspaceId();
      const response = await workspaceService.getWorkspaces(workspaceId).catch(snackbarStore.handleError);

      if (!response) throw Error();

      const [currentWorkspace] = response.data.dtos;

      this.currentWorkspace = currentWorkspace

      return currentWorkspace;
    },
    async getWorkspaceId(): Promise<string> {
      const snackbarStore = useSnackbarStore();

      if (this.currentWorkspaceId) return this.currentWorkspaceId;
      
      const response = await workspaceService.getCurrentWorkspaceId().catch(snackbarStore.handleError);
      
      this.currentWorkspaceId = response?.data?.currentWorkspaceId || '';
    
      return this.currentWorkspaceId;
    },
    async searchProducts(keyword: string): Promise<Product[]> {
      const path = await this.buildPath(`products?q=${keyword}`);
      const { data } = await workspaceAxios.get(path, { withCredentials: true });

      return data.products;
    },
    async getProducts(ids: string[]): Promise<Product[]> {
      const path = await this.buildPath(`products?ids=${ids.join(',')}`);
      const { data } = await workspaceAxios.get(path, { withCredentials: true });

      return data.products;
    }, 
    async buildPath(route: string) {
      const workspaceId = await this.getWorkspaceId();

      return `${workspaceId}/${route}`;
    },
  },
})
