<script lang="ts" setup>
interface Props {
  conversationsCount: number;
  conversationsCountPrevious: number;
  resolutionsCount: number;
  resolutionsCountPrevious: number;
}

const props = defineProps<Props>();

const value = computed((): number => {
  if (!props.conversationsCount) return 0;

  return (props.resolutionsCount * 100) / props.conversationsCount;
});

const valueFormatted = computed((): string => {
  if (!value.value) return '--';

  const valueRounded = Math.round(value.value * 10) / 10;

  return `${valueRounded}%`
});

const increase = computed((): number => {
  const diff = props.resolutionsCount - props.resolutionsCountPrevious;

  return (diff / props.resolutionsCountPrevious) * 100;
});
</script>

<template>
  <DashboardOverviewField
    :label="$t('AI Resolution Rate')"
    :increase="increase"
  >
    {{ valueFormatted }}
  </DashboardOverviewField>
</template>
