<script lang="ts" setup>
interface Props {
  value?: Date
  disabled?: boolean
}

interface Emits {
  (e: 'update', value: Date): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const hourOptions: string[] = [];
const minuteOptions: string[] = [];

for (let i = 0; i < 24; i++) {
  hourOptions.push(`${i}`.padStart(2, '0'));
}

for (let i = 0; i < 60; i++) {
  minuteOptions.push(`${i}`.padStart(2, '0'));
}

const hourValue = computed(() => {
  if (!props.value) return '00';

  return props.value.getHours().toString().padStart(2, '0');
});

const minuteValue = computed(() => {
  if (!props.value) return '00';

  return props.value.getMinutes().toString().padStart(2, '0');
});

const handleHourSelect = (event: Event) => {
  const value = (event.target as HTMLSelectElement).value;
  const hours = parseInt(value, 10);
  const newDate = props.value ? new Date(props.value) : new Date();

  newDate.setHours(hours);

  emits('update', newDate);
}

const handleMinuteSelect = (event: Event) => {
  const value = (event.target as HTMLSelectElement).value;
  const minutes = parseInt(value, 10);
  const newDate = props.value ? new Date(props.value) : new Date();

  newDate.setMinutes(minutes);

  emits('update', newDate);
}
</script>

<template>
  <div
    class="d-inline-flex align-center pa-2 app-time-input"
  >
    <VIcon
      icon="mingcute:time-line"
      class="mr-2"
    />
    <select
      :disabled="disabled"
      :value="hourValue"
      @input="handleHourSelect"
    >
      <option
        v-for="hour in hourOptions"
        :key="hour"
      >
        {{ hour }}
      </option>
    </select>
    :
    <select
      :disabled="disabled"
      :value="minuteValue"
      @input="handleMinuteSelect"
    >
      <option
        v-for="minute in minuteOptions"
        :key="minute"
      >
        {{ minute }}
      </option>
    </select>
  </div>
</template>

<style scoped>
  .app-time-input {
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgb(203, 213, 225);
  }
</style>
