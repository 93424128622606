<script lang="ts" setup>
const minutesPerConversation = 8.6;

interface Props {
  resolutionsCount: number;
}

const props = defineProps<Props>();

const totalMinutes = computed((): number => {
  return props.resolutionsCount * minutesPerConversation;
});

const hours = computed((): number => {
  return Math.floor(totalMinutes.value / 60);
});

const minutes = computed((): number => {
  return Math.round(totalMinutes.value % 60);
});
</script>

<template>
  <DashboardOverviewField
    :label="$t('Time Saved')"
  >
    <template v-if="hours">
      {{ hours }}<small>h</small>&nbsp;
    </template>
    <template v-if="minutes || !hours">
      {{ minutes }}<small>m</small>
    </template>
  </DashboardOverviewField>  
</template>
