<script lang="ts" setup>
import { useAgentStore } from '@/stores/useAgentStore';
import { useOrganizationStore } from '@/stores/useOrganizationStore';
import { useThemeConfig } from '@core/composable/useThemeConfig'
import Intercom from '@intercom/messenger-js-sdk'
import { useJuneAnalytics } from '@/plugins/june/useJuneAnalytics'
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

const { appRouteTransition, isLessThanOverlayNavBreakpoint } = useThemeConfig()
const { width: windowWidth } = useWindowSize()
const juneAnalytics = useJuneAnalytics();
const agentStore = useAgentStore()
const orgStore = useOrganizationStore()
const workspaceStore = useWorkspaceStore();

const { currentAgent } = storeToRefs(agentStore);
const { currentOrganization } = storeToRefs(orgStore);
const { currentWorkspace } = storeToRefs(workspaceStore);

const didIdentifyUser = ref(false);

watch(currentAgent, async val => {
  if (val) {
    juneAnalytics.identify(val.id, val.email, val.role);
    didIdentifyUser.value = true;
  }

  if(val && import.meta.env.VITE_USE_INTERCOM === 'true') {
    Intercom({
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: 'wuzwleoy',
      user_id: val.id,
      name: `${val.firstName} ${val.lastName}`, 
      email: val.email,
      avatar: val.avatar,
      company: currentOrganization.value?.name
    })
  }
})

watchEffect(() => {
  if (currentWorkspace.value && didIdentifyUser.value) {
    juneAnalytics.group(currentWorkspace.value.id, currentWorkspace.value.name, currentWorkspace.value.avatar);
  } 
})
</script>

<template>
  <VerticalNavLayout>
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <VBtn
          v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
          icon
          variant="text"
          color="default"
          class="ms-n3"
          size="small"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon
            icon="tabler-menu-2"
            size="24"
          />
        </VBtn>
      </div>
    </template>

    <ErrorBoundary>
      <RouterView v-slot="{ Component }">
        <Transition
          :name="appRouteTransition"
          mode="out-in"
        >
          <Component :is="Component" />
        </Transition>
      </RouterView>
    </ErrorBoundary>

    <template #content-loading>
      <LoadingLogo />
    </template>
  </VerticalNavLayout>
</template>
