<script lang="ts" setup>
import { useDashboardStore } from '@/stores/useDashboardStore';
import { useLayoutStore } from '@/stores/useLayoutStore';

const layoutStore = useLayoutStore();
const { isVerticalNavExpanded } = storeToRefs(layoutStore);

const defaultTo = new Date();
const defaultFrom = new Date(defaultTo);

const chartKey = ref(0);

function rerenderChart() {
  setTimeout(() => {
    chartKey.value = chartKey.value + 1;
  }, 500);
}

watch(isVerticalNavExpanded, rerenderChart);

defaultFrom.setDate(defaultTo.getDate() - 30);

const dateRangeFrom = ref<Date>(defaultFrom);
const dateRangeTo = ref<Date>(defaultTo);

const dashboardStore = useDashboardStore();
const {
  conversationsCount,
  conversationsCountPrevious,
  resolutionsCount,
  resolutionsCountPrevious,
  newSubscribersCount,
  newSubscribersCountPrevious,
  attributedRevenue,
  attributedRevenuePrevious,
  totalRevenue,
  totalCost,
} = storeToRefs(dashboardStore);

function handleDateRangeUpdate(event: { from: Date, to:Date }) {
  dateRangeFrom.value = event.from;
  dateRangeTo.value = event.to;

  dashboardStore.fetchData(dateRangeFrom.value, dateRangeTo.value);
}

onMounted(() => {
  dashboardStore.fetchData(dateRangeFrom.value, dateRangeTo.value);
});

const currency = 'EUR';
</script>

<template>
  <div class="dashboard-view h-100 overflow-y-auto overflow-x-hidden">
    <DashboardPageHeader />
    <div class="pa-5 d-flex ga-4 px-9">
      <div class="flex-fill d-flex flex-column ga-4">
        <DashboardPerformanceOverviewHeader
          :from-date="dateRangeFrom"
          :to-date="dateRangeTo"
          @update="handleDateRangeUpdate"
        />
        <DashboardPerformanceOverview
          :conversations-count="conversationsCount"
          :conversations-count-previous="conversationsCountPrevious"
          :resolutions-count="resolutionsCount"
          :resolutions-count-previous="resolutionsCountPrevious"
          :new-subscribers-count="newSubscribersCount"
          :new-subscribers-count-previous="newSubscribersCountPrevious"
          :attributed-revenue="attributedRevenue"
          :attributed-revenue-previous="attributedRevenuePrevious"
          :total-revenue="totalRevenue"
          :total-cost="totalCost"
          :currency="currency"
        />
        <DashboardRevenueAttributedChart
          :key="chartKey"
          :currency="currency"
        />
        <DashboardAiSessionsChart
          :key="chartKey"
        />
      </div>
      <DashboardFeedListSection>
        <div
          style="max-height: 800px;"
          class="overflow-auto"
        >
          <DashboardFeedListItems />
        </div>
      </DashboardFeedListSection>
    </div>
  </div>
</template>
