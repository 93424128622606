import { RouteLocation } from 'vue-router'

import axiosApi from '@/plugins/axios/axiosApi'
import router from '../index'

export const authGuard = async (to: RouteLocation, from: RouteLocation) => {
    if (from.name) {
      return true;
    }

    const isPublic = to.matched.some((route) => route.meta.isPublic)

    if (isPublic) {
      return true
    }

    if (to.path.includes('billing')) {
      await axiosApi.get('session/verify', { 
        withCredentials: true,
        validateStatus: (status) => status === 404
      }).catch((e) => {
        router.push({ path: '/settings' })
      })
      return true
    }

    await axiosApi.get('session/verify', { 
      withCredentials: true,
    }).catch((e) => {
      if (!e.response) {
        router.push({ path: '/error' });
        return;
      }

      if(e.response.status === 404) {
        router.push({ path: '/billing' })
      } else {
        router.push({ path: '/login' })
      }
    });

    return true
  }
