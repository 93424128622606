<script lang="ts" setup>
import { formatCurrency } from '@/@core/utils/formatters';

interface Props {
  attributedRevenue: number; 
  attributedRevenuePrevious: number;
  totalRevenue: number;
  currency: string;
}

const props = defineProps<Props>();

const value = computed((): string => {
  if (!props.attributedRevenue) return '--';

  return formatCurrency(props.attributedRevenue / 100, props.currency);
});

const increase = computed(() => {
  const diff = props.attributedRevenue - props.attributedRevenuePrevious;

  return (diff / props.attributedRevenuePrevious) * 100;
});

const attributionPercent = computed((): number => {
  return (100 * props.attributedRevenue) / props.totalRevenue;
});

const label = computed((): string => {
  const percentRounded = Math.round(attributionPercent.value * 10) / 10;

  const suffix = isNaN(percentRounded) ? '' : ` (${percentRounded}%)`;

  return `Revenue Attributed${suffix}`;
});
</script>

<template>
  <DashboardOverviewField
    :label="label"
    :increase="increase"
  >
    {{ value }}
  </DashboardOverviewField>
</template>
