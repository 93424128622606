import { formatCurrency, formatDateToMonthShort } from "@/@core/utils/formatters";
import { ChartGrouping } from "@/types/chart.types";
import { PriceConverter } from "@/utils/money/priceConverter";

export const getLineChartConfig = (labels: string[], title?: string, currency?: string) => {
    return {
        chart: {
          parentHeightOffset: 0,
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        title: {
          text: title,
          align: 'left'
        },
        colors: [
          '#D5D1FF',
          '#FFE3EA',
          '#B8F1BE',
          '#C2F0FF',
          '#FFF0BB',
          '#F6F6F6',
          '#11101d'
        ],
        stroke: {
          curve: 'smooth',
          colors: ['#26A0FC'],
          width: 2,
        },
        dataLabels: { enabled: false },
        markers: {
          size: 4,
          strokeWidth: 0,
          colors: ['#26A0FC'],
          hover: {
            size: 8,
            sizeOffset: 3
          }
        },
        grid: {
          padding: { top: -10 },
          borderColor: '#D7D7D7',
          xaxis: {
            lines: { show: false },
          },
        },
        tooltip: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          custom(data: any) {
            const value = data.series[data.seriesIndex][data.dataPointIndex];
            const valueFormatted = currency ? formatCurrency(value, currency) : value;

            return `<div class='bar-chart pa-2'>
              <span>${valueFormatted}</span>
            </div>`
          },
        },
        yaxis: {
          labels: {
            style: { colors: '#000', fontSize: '0.8125rem' },
          },
        },
        xaxis: {
          categories: labels,
          axisBorder: { show: false },
          axisTicks: { color: '#000' },
          crosshairs: {
            stroke: { color: '#000' },
          },
          labels: {
            style: { colors: '#000', fontSize: '0.8125rem' },
          },
        },
    }
}

export const getDonutConfig = (labels: string[], title?: string) => {
    return {
        chart: {
            type: 'donut',
        },
        title: {
          text: title,
          align: 'left',
        },
        labels: labels,
        responsive: [{
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        tooltip: {
          y: {
            formatter: function(value: number) {
              return `€${PriceConverter.convert(value)}`;
            } 
          }
        }
    }
}

export const getBarConfig = (labels: string[]) => {
  return {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        barHeight: '88%',
        borderRadius: 4,
        borderRadiusApplication: 'end',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom'
        },
      }
    },
    colors: ['#26A0FC', '#268AD5', '#266FA6', '#264F6E', '#267252', '#34D226'],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ['#fff']
      },
      formatter: function (_val: number, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      offsetX: 0,
      dropShadow: {
        enabled: false
      }
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    legend: {
      show: false,
    },
  }
}

function otherYearSuffix(date: Date): string {
  const today = new Date();

  if (date.getFullYear() === today.getFullYear()) return '';

  return ` '${date.getFullYear().toString().slice(-2)}`;
}

export const chartGroupingDateFormatter: Record<ChartGrouping, (date: Date) => string> = {
  [ChartGrouping.Daily]: (date: Date) => {
    return formatDateToMonthShort(date, false) + otherYearSuffix(date);
  },
  [ChartGrouping.Weekly]: (date: Date) => {
    const tempDate = new Date(date.getTime());

    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7));

    const yearStart = new Date(tempDate.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((tempDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

    return `${weekNumber}` + otherYearSuffix(date);
  },
  [ChartGrouping.Monthly]: (date: Date) => {
    return date.toLocaleString('en-US', { month: 'short' }) + otherYearSuffix(date);
  },
};
