<script setup lang="ts">
interface Props {
  count: number
  fontColor?: string
  backgroundColor?: string
  size: {
    width: number
    height: number
  }
}

const props = defineProps<Props>()

const getTextForCount = () => {
    return props.count > 99 ? '99+' : props.count
}
</script>

<template>
  <div
    class="badge-background"
    :style="[
      `background-color: ${ backgroundColor || '#fff'}`,
      `width: ${size.width}px; height: ${size.height}px;`
    ]"
  >
    <span
      :style="[
        `color: ${ fontColor || '#000'}`, 
        'width: 100%; text-align: center; float:right; font-size: 13px;'
      ]"
    >{{ getTextForCount() }}</span>
  </div>
</template>

<style>
.badge-background {
    border-radius: 10px;
    background-color: #DFF6F4;
    border: 1px solid rgba(0,0,0, 0.1);
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
