import axios, { AxiosError } from 'axios'
import router from '@/router/index'
import { RequestService } from './requestService'

interface HoolaError {
  error: string
  message: string
  statusCode: number
}

const axiosApi = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  headers: {
    Accept: "application/json",
  },
});

axiosApi.interceptors.response.use(
  (response) => { return response },
  (error: AxiosError<HoolaError>) => {
    if (error.response) {
    const { status } = error.response
      const sessionMissing = status === 401 || status === 403
      if (sessionMissing) {
        router.push({ path: '/login' })
      }
    }

    return Promise.reject(error)
  }
)

const api = new RequestService(axiosApi, true)  

export default api;
