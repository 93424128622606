<script lang="ts" setup generic="T">
interface Props {
  options: T[];
  value: T;
}

interface Emits {
  (e: 'update:value', value: T): void;
}

defineProps<Props>();
defineEmits<Emits>();

const isMenuOpen = ref(false);
</script>

<template>
  <div>
    <PickerTrigger>
      {{ value }}
      <VIcon
        :size="12"
        icon="tabler-chevron-down"
      />
    </PickerTrigger>
    <AppMenu
      v-model="isMenuOpen"
      activator="parent"
      :close-on-content-click="false"
      :content-props="{ style: 'background-color: white; overflow: hidden;' }"
    >
      <VList>
        <VListItem
          v-for="item in options"
          :key="`${item}`"
          class="cursor-pointer"
          @click="$emit('update:value', item); isMenuOpen = false"
        >
          {{ item }}
        </VListItem>
      </VList>
    </AppMenu>
  </div>
</template>
