import { WhatsAppMediaHeader, WhatsAppTemplate, WhatsAppTemplateBody, WhatsAppTemplateButtons, WhatsAppTemplateFooter, WhatsAppTemplateHeader, WhatsAppTemplateTextHeader } from "@/types/whatsapp-template.types";
import { urlValidator } from '@/@core/utils/validators'
import { PhoneNumberUtils } from'@/utils/PhoneNumberUtils'
import { TemplateType } from "@/types/template.types";

export class WhatsAppTemplateValidator {
    static validate(state: TemplateType, name: string, template: WhatsAppTemplate): string[] {
        let errors = [] as string[]
        if(state === 'template') {
            if(template.language === '') {
                errors.push("You must select a language for the template")
            }
    
            template.components.forEach(component => {
                switch(component.type) {
                    case "HEADER":
                        errors = errors.concat(WhatsAppTemplateValidator.validateHeader(component as WhatsAppTemplateHeader))
                        break;
                    case "BODY":
                        errors = errors.concat(WhatsAppTemplateValidator.validateBody(component as WhatsAppTemplateBody))
                        break;
                    case "FOOTER":
                        errors = errors.concat(WhatsAppTemplateValidator.validateFooter(component as WhatsAppTemplateFooter))
                        break
                    case "BUTTONS":
                        errors = errors.concat(WhatsAppTemplateValidator.validateTemplateButtons(component as WhatsAppTemplateButtons))
                        break
                }
            })
        }

        return errors
    }

    private static validateHeader(header: WhatsAppTemplateHeader): string[] {
        const errors = [] as string[]

        switch(header.format) {
            case "IMAGE":
            case "VIDEO":
            case "DOCUMENT": {
                const mediaHeader = header as WhatsAppMediaHeader
                if(mediaHeader.example.header_handle === undefined || mediaHeader.example.header_handle?.length === 0) {
                    errors.push("Media header must contain at least one file")
                }
                break;
            }
            case "TEXT": {
                const textHeader = header as WhatsAppTemplateTextHeader
                if(textHeader.text.length === 0) {
                    errors.push("Text header can't be empty")
                }

                if(textHeader.text.length > 60) {
                    errors.push("Header text must be 60 characters or less")
                }
                break;
            }
        }

        return errors
    }

    private static validateBody(body: WhatsAppTemplateBody): string[] {
        const errors = [] as string[]
        const variableCount = body.text.split(WhatsAppTemplateValidator.START_OF_VARIABLE).length - 1

        if(body.text.length === 0 || body.text === '') {
            errors.push('Body must contain text')
        }

        if(body.text.length > WhatsAppTemplateValidator.BODY_LIMIT) {
            errors.push(`Body too long, limit is 4096. Actual length : ${body.text.length}`)
        }

        if(body.example && variableCount !== body.example.body_text.length) {
            errors.push(`Incorrect examples for variables - text contains ${variableCount} variables, but only ${body.example.body_text.length} examples given`)
        }

        return errors
    }

    private static validateFooter(footer: WhatsAppTemplateFooter): string[] {
        const errors = [] as string[]

        return errors
    }

    private static validateTemplateButtons(component: WhatsAppTemplateButtons): string[] {
        let errors = [] as string[]
        
        component.buttons.forEach(button => {
            switch(button.type) {
                case "URL":
                   errors = errors.concat(WhatsAppTemplateValidator.checkButtonLength(button.text))

                   if(urlValidator(button.url) !== true) {
                        errors.push(`Url with button text ${button.text} is not a valid url`)
                   }
                   break;
                case "PHONE_NUMBER":
                    errors = errors.concat(WhatsAppTemplateValidator.checkButtonLength(button.text))

                    if(!PhoneNumberUtils.validate(button.phone_number)) {
                        errors.push(`Phone number button with text ${button.text} not a valid phone number`)
                    }

                    break;
                case "QUICK_REPLY":
                    errors = errors.concat(WhatsAppTemplateValidator.checkButtonLength(button.text))
                    break;
                case "COPY_CODE":
                    if(button.example.length > 15) {
                        errors.push(`Copy Cody button with code ${button.example} too long, limit is 15 characters`)
                    }
                    break;
            }
        })

        return errors
    }

    private static checkButtonLength(text: string): string[] {
        if(text.length > WhatsAppTemplateValidator.BUTTON_TEXT_LIMIT) {
            return [`Text "${text}" is too long, limit is ${WhatsAppTemplateValidator.BUTTON_TEXT_LIMIT} characters`]
        } else {
            return []
        }
    }

    static HEADER_TEXT_LIMIT = 60
    static BUTTON_TEXT_LIMIT = 25
    static BODY_LIMIT = 4096
    static START_OF_VARIABLE = '{{'
}
