import workspace from '@/plugins/axios/workspace';
import axios from "@/plugins/axios/axiosApi"
import { WorkspaceDto } from '@/types/workspace.types';

interface GetWorkspaceSettingsResponse {
  dtos: {
    id: string,
    linkSettings: {
      linkTrackingEnabled: boolean,
    },
    inboxSettings: {
      selfAssignByReplyingEnabled: boolean,
    },
  }
}

interface GetWorkspacesResponse {
  dtos: WorkspaceDto[]
}

interface GetCurrentWorkspaceIdResponse {
  currentWorkspaceId: string | undefined
}

export type PatchWorkspaceSettingsBody =  {
  linkSettings: {
    linkTrackingEnabled: boolean,
  }
} | {
  inboxSettings: {
    selfAssignByReplyingEnabled: boolean,
  }
}

export const workspaceService = {
  async getWorkspaceSettings(workspaceId: string) {
    return workspace.get<GetWorkspaceSettingsResponse>(`${workspaceId}/workspace-settings`, { withCredentials: true })
  },

  async patchWorkspaceSettings(workspaceId: string, body: PatchWorkspaceSettingsBody) {
    return workspace.patch(`${workspaceId}/workspace-settings`, body);
  },

  async getWorkspaces(workspaceId: string) {
    return workspace.get<GetWorkspacesResponse>(`${workspaceId}/workspaces`, { withCredentials: true });
  },

  async getCurrentWorkspaceId() {
    return axios.get<GetCurrentWorkspaceIdResponse>('session/workspace', { withCredentials: true });
  },
}
