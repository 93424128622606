import { v4 as uuidv4 } from 'uuid';
import { qrButtonValidator } from '@/utils/templateValidators';
import {IsoLang} from '@/types/language.types'
import { CurrentWorkspace } from '@/utils/current-workspace';
import { WhatsAppTemplate, WhatsAppTemplateButton, WhatsAppTemplateButtonType, WhatsAppTemplateHeader, WhatsAppTemplateQuickReplyButton, WhatsAppTemplateCategory, WhatsAppTemplateHeaderFormat, WhatsAppTemplateTextHeader, WhatsAppTemplateFooter, WhatsAppTemplateUrlButton } from '@/types/whatsapp-template.types';
import { Header, WhatsAppInteractiveMessage, WhatsAppMediaMessage, WhatsAppMediaObject, WhatsAppTextMessage, WhatsAppInteractiveObject } from '@/types/whatsapp-message.types';
import { WhatsAppTemplateUtils } from '@/utils/WhatsAppTemplateUtils';
import workspace from '@/plugins/axios/workspace';
import { WhatsAppTemplateValidator } from '@/utils/WhatsAppTemplateValidator';
import { WhatsAppMessageType, WhatsAppObject } from '@/types/whatsapp.types';
import { OptInDto } from '@/stores/useOptInStore';
import { useSnackbarStore } from '@/stores/useSnackbarStore';
import { TemplateType } from '@/types/template.types';

export type ReplyButtonType = 'reply'
export type TemplateButtonType = 'quick_reply' | 'url'
export type TemplateCategory = 'MARKETING' | 'AUTHENTICATION' | 'UTILITY'
export type AssetType =  'video' | 'image' | 'document' | 'audio' | 'sticker'
export enum TemplateOrigin {
    APP = 'App',
    AUTOMATIONS = 'Automations',
    BROADCASTS = 'Broadcasts',
    API = 'API',
    UNKNOWN = 'Unknown',
    FLOWS = 'Flows',
    ONE_OFF = 'ONE_OFF'
}

export interface Asset {
    fileName: string
    url: string
    type: AssetType
}

export interface List {
    button: string
    sections: ListSection[]
}

export interface ListRow {
    id: string
    title: string
    description: string
}

export interface ListSection {
    title: string
    rows: ListRow[]
}

export interface QuickReplyButton {
    type: ReplyButtonType
    reply: {
        title: string
        id: string
    }
}

export interface Footer {
    text: string
}


export interface ActionObject {
    button?: string
    buttons?: (QuickReplyButton | WhatsAppTemplateUrlButton)[]
    catalog_id?: string
    product_retailer_id?: string
    sections?: ListSection[]
}

export type OutlinkButtonType = 'URL' | 'PHONE_NUMBER'

export interface OutlinkButton {
    type: OutlinkButtonType
    text: string,
    url: string
}

export interface PendingTemplateCreate {
    name: string
    type: TemplateType
    category?: TemplateCategory
    language?: IsoLang
    body?: string
    assetUrls?: string[]
    header?: string
    buttons?: {
        quickReply?: QuickReplyButton[]
        list?: List
        outlink?: OutlinkButton
    }
}

export interface InteractiveObject {
     body: { text: string }
     action: { 
        buttons: { type: string, reply: { id: string, title: string }}[]
    }
    header: {
        type: string
        image: {
            link: string
        }
    }
}

interface State {
    currentStateType: TemplateType,
    templateName: '',
    tempBody: string | undefined,
    template: {
        template: WhatsAppTemplate,
        isDraft: boolean
    },
    interactiveMessage: {
        template: WhatsAppInteractiveMessage,
        buttons: {
            quickReply: QuickReplyButton[]
            list: List
            outlink: WhatsAppTemplateUrlButton | undefined
        },
    }
    textMessage: WhatsAppTextMessage
    assets: Asset[]
    currentWorkspaceId: string | undefined
    templateId: string | undefined
    inboundMessage: string | undefined
}

export const useWhatsappTemplateStore = defineStore('whatsappTemplateStore', {
  state: (): State => ({
    currentStateType: 'template',
    templateName: '',
    assets: [],
    tempBody: undefined,
    template: {
        template: {
            name: '',
            language: '',
            category: 'MARKETING',
            components: []
        },
        isDraft: true,
    },
    interactiveMessage: {
        template: {
            type: 'interactive',
            interactive: {
                action: {},
                body: { text: '' },
                type: 'button'
            }
        },
        buttons: {
            quickReply: [],
            list: { button: '', sections: [] },    
            outlink: undefined,    
        },
    },
    textMessage: {
        type: 'text',
        text: {
            body: ''
        },
        preview_url: true
    },
    currentWorkspaceId: undefined,
    templateId: undefined,
    inboundMessage: undefined
}),
  getters: {
    headerText: (state): string => {
        switch(state.currentStateType) {
            case 'interactive':
                return state.interactiveMessage.template.interactive?.header?.text || ''
            case 'template':
                return (WhatsAppTemplateUtils.getHeaderComponent(state.template.template.components) as WhatsAppTemplateTextHeader)?.text || ''
        }

        return ''
    },
    footerText: (state): string => {
        switch(state.currentStateType) {
            case 'interactive':
                return state.interactiveMessage.template.interactive?.footer?.text || ''
            case 'template':
                return WhatsAppTemplateUtils.getFooterComponent(state.template.template.components)?.text || ''
        }

        return '';
    },
    header: (state): WhatsAppTemplateHeader | undefined => {
        switch(state.currentStateType) {
            case 'interactive':
                return state.interactiveMessage.template.interactive?.header as any as WhatsAppTemplateHeader
            case 'template':
                return WhatsAppTemplateUtils.getHeaderComponent(state.template.template.components) as WhatsAppTemplateHeader
        }
    },
    allowHeaderFooter: (state): boolean => {
        if (state.currentStateType === 'template') {
            return true;
        }

        return  state.interactiveMessage.buttons.quickReply.length > 0 || state.interactiveMessage.buttons.outlink !== undefined;
    },
    isTextHeader: (state): boolean => {
        switch(state.currentStateType) {
            case 'interactive':
                return state.interactiveMessage.template.interactive?.header?.type === 'text';
            case 'template':
                return WhatsAppTemplateUtils.getHeaderComponent(state.template.template.components)?.format === 'TEXT';
        }

        return false;
    },
    allTemplateButtons: (state) => {
        return WhatsAppTemplateUtils.getAllButtons(state.template.template.components)
    },
    allInteractiveMessageButtons: (state) => {
        return state.interactiveMessage.buttons.quickReply;
    },
    maxQrButtonLength: (state) => {
        switch(state.currentStateType) {
            case 'interactive':
                return 3
            case 'template':
                return 10
            default:
                return 0
        }
    },
    templateLanguage: (state) => {
        return state.template.template.language;
    },
    bodyLimit(): number {
        return 1024;
    },
  },
  actions: {
    reset() {
        this.templateName = '',
        this.assets = [],
        this.tempBody = undefined,
        this.template = {
            template: {
                name: '',
                language: '',
                category: 'MARKETING',
                components: []
            },
            isDraft: true,
        },
        this.interactiveMessage = {
            template: {
                type: 'interactive',
                interactive: {
                    action: {},
                    body: { text: '' },
                    type: 'button'
                }
            },
            buttons: {
                quickReply: [],
                list: { button: '', sections: [] },    
                outlink: undefined,    
            },
        },
        this.textMessage = {
            type: 'text',
            text: {
                body: ''
            },
            preview_url: true
        },
        this.currentWorkspaceId = undefined,
        this.templateId = undefined,
        this.inboundMessage = undefined
    },
    preview(optInDto: OptInDto) {
        this.reset()

        const obj = optInDto.optinTemplate.object as WhatsAppInteractiveObject
        const buttons = obj.action.buttons 
        const acceptButton = buttons![0] as QuickReplyButton
        const rejectButton = buttons![1] as QuickReplyButton

        this.template.template.components = []
        this.inboundMessage = optInDto.triggerText
        this.setText(obj.body.text)
        this.addQrButton(acceptButton.reply.title)
        this.addQrButton(rejectButton.reply.title)
        this.setAssetsForHeader(obj.header)
    },
    validate() {
        return WhatsAppTemplateValidator.validate(this.currentStateType, this.templateName, this.template.template)
    },

    load(object: WhatsAppObject, type: WhatsAppMessageType) {
        this.reset()

        this.currentStateType = type === 'template' ? 'template' : 'interactive';

        switch(type) {
            case 'text':
                this.interactiveMessage.template.text = object as WhatsAppTextMessage
                break;
            case 'interactive': {
                const interactiveObject = (object as WhatsAppInteractiveObject)
                this.interactiveMessage.template.text = { type: 'text', text: { body: interactiveObject.body.text } }
                break;
            }
            case 'audio': {
                const { audio } = (object as WhatsAppMediaMessage);
    
                if(audio?.caption) {
                    this.interactiveMessage.template.text = { type: 'text', text: { body: audio.caption }, preview_url: true }
                }
                break;
            }
            case 'video': {
                const { video } = (object as WhatsAppMediaMessage);

                if(video?.caption) {
                    this.interactiveMessage.template.text = { type: 'text', text: { body: video.caption }, preview_url: true }
                }
                break;
            }
            case 'document': {
                const { document } = (object as WhatsAppMediaMessage);

                if(document?.caption) {
                    this.interactiveMessage.template.text = { type: 'text', text: { body: document.caption }, preview_url: true }
                }
                break;
            }
            case 'image':
            case 'sticker': {
                const image = (object as WhatsAppMediaMessage).image

                if(image?.caption) {
                    this.interactiveMessage.template.text = { type: 'text', text: { body: image.caption }, preview_url: true }
                }
                break;
            }
        }

        setTimeout(() => {
            switch(type) {
                case 'template':
                    this.template.template = object as WhatsAppTemplate
                    break;
                case 'interactive': {
                    const interactiveObject = (object as WhatsAppInteractiveObject)
                    this.interactiveMessage.template.interactive = interactiveObject
                    this.interactiveMessage.template.text = { type: 'text', text: { body: interactiveObject.body.text } }
                    this.interactiveMessage.buttons.quickReply = (interactiveObject.action.buttons?.filter(button => button.type === 'reply') as QuickReplyButton[]) || []
                    this.interactiveMessage.buttons.outlink = interactiveObject.action.buttons?.filter(button => button.type === 'URL')[0] as WhatsAppTemplateUrlButton
                    this.setAssetsForHeader(this.interactiveMessage.template.interactive.header)
                    break;
                }
                case 'audio': {
                    const audio = (object as WhatsAppMediaMessage).audio
                    this.assets.push({ fileName: audio!.filename || 'AUDIO', url: audio!.link || '', type: 'audio' })
                    break;
                }
                case 'document': {
                    const document = (object as WhatsAppMediaMessage).document
                    this.assets.push({ fileName: document!.filename || 'DOCUMENT', url: document!.link || '', type: 'document' })
                    break;
                }
                case 'video': {
                    const video = (object as WhatsAppMediaMessage).video
                    this.assets.push({ fileName: video!.filename || 'VIDEO', url: video!.link || '', type: 'video' })
                    break;
                }
                case 'text':
                    this.interactiveMessage.template.text = object as WhatsAppTextMessage
                    break;
                case 'image':
                case 'sticker': {
                    const image = (object as WhatsAppMediaMessage).image
                    this.assets.push({ fileName: image!.filename || 'IMAGE', url: image!.link || '', type: 'image' })
                    if(image?.caption) {
                        this.interactiveMessage.template.text = { type: 'text', text: { body: image.caption }, preview_url: true }
                    }
                    break;
                }
            }
        }, 400)
    },

    setAssetsForHeader(header?: Header) {
        if(header) {
            if(header.image) {
                this.assets.push({ fileName: 'IMAGE', url: header.image.link, type: 'image' })
            }
            else if(header.document) {
                this.assets.push({ fileName: header.document.fileName || 'DOCUMENT', url: header.document.link, type: 'document' })
            }
            else if(header.video) {
                this.assets.push({ fileName: 'VIDEO', url: header.video.link, type: 'video' })
            }
        }
    },

    async create(origin: TemplateOrigin): Promise<{ object: WhatsAppObject, type: WhatsAppMessageType }> {
        switch(this.currentStateType) {
            case 'interactive': {
                let template;
                if(this.usingButtons()) {
                    template = this.interactiveMessage.template.interactive
                    template.action = {}
                    template.action.buttons = []
                    template.action.buttons = template.action.buttons.concat(this.interactiveMessage.buttons.quickReply)
                    template.body = { text: this.getText() }

                    if(this.interactiveMessage.buttons.outlink) {
                        template.action.buttons.push(this.interactiveMessage.buttons.outlink as WhatsAppTemplateUrlButton)
                    }

                    if(this.assets.length > 0) {
                        const asset = this.assets[0]

                        switch(asset.type) {
                            case 'video':
                                template.header = {
                                    type: 'video',
                                    video: { link: asset.url }
                                };
                                break;
                            case 'image':
                                template.header = {
                                    type: 'image',
                                    image: { link: asset.url }
                                }
                                break;
                            case 'document':
                                template.header = {
                                    type: 'document',
                                    document: { link: asset.url, fileName: asset.fileName }
                                };
                                break;
                        }
                    }

                    if(template.header === undefined) {
                        delete template.header
                    }

                    if(template.footer === undefined) {
                        delete template.footer
                    }

                    return { object: template, type: 'interactive' }
                } else if (this.assets.length > 0) {
                    let mediaObject: WhatsAppMediaObject
                    const asset = this.assets[0]
                    const mediaMessage = {
                        type: asset.type
                    } as WhatsAppMediaMessage

                    switch(asset.type) {
                        case 'video':
                            mediaMessage.video = { link: asset.url }
                            if(this.getText() !== '') {
                                mediaMessage.video.caption = this.getText() || ''
                            }
                            mediaObject = mediaMessage.video
                            break;
                        case 'sticker':
                        case 'image':
                            mediaMessage.image = { link: asset.url }
                            if(this.getText() !== '') {
                                mediaMessage.image.caption = this.getText()
                            }
                            mediaObject = mediaMessage.image
                            break;
                        case 'document':
                            mediaMessage.document = { link: asset.url }
                            if(this.getText() !== '') {
                                mediaMessage.document.caption = this.getText()
                            }
                            mediaObject = mediaMessage.document
                            break;
                        case 'audio':
                            mediaMessage.audio = { link: asset.url }
                            if(this.getText() !== '') {
                                mediaMessage.audio.caption = this.getText()
                            }
                            mediaObject = mediaMessage.audio
                            break;
                        default:
                            throw new Error("Not supported")
                    }
                    
                    return { object: mediaObject, type: mediaMessage.type }
                } else {
                    const textMessage = {
                        type: 'text',
                        text: {
                            body: this.getText()
                        },
                        preview_url: true
                    } as WhatsAppTextMessage

                    return { object: textMessage, type: 'text' }
                }
            }
            case 'template': {
                const path = await this.buildPath(`templates`)

                this.processTemplate()
               
                if (origin !== TemplateOrigin.FLOWS && origin !== TemplateOrigin.ONE_OFF) {
                    await workspace.post<string[]>(path, {
                        type: 'template',
                        object: this.template.template,
                        origin: origin,
                    }, { withCredentials: true })
                }

                return { object: this.template.template, type: 'template' }
            }
        }

        throw new Error("Type not supported")
    },

    getText(): string {
        switch(this.currentStateType) {
            case 'interactive':
                return this.interactiveMessage.template.text?.text.body || ''
            case 'template':
                return WhatsAppTemplateUtils.getTextComponent(this.template.template.components)?.text || ''
        }

        return ''
    },

    getLanguage(): string {
        return  this.template.template.language;
    },

    setLanguage(language: string) {
        this.template.template.language = language;
    },

    setDefaultLanguage() {
        if (!this.templateLanguage) {
            this.setLanguage('en');
        }
    },

    getCategory() {
        if (this.currentStateType === 'template') return this.template.template.category;

        return  'SIMPLE';
    },

    setCategory(category: WhatsAppTemplateCategory) {
        this.template.template.category = category;
    },

    setHeaderText(text: string) {
        switch(this.currentStateType) {
            case 'interactive':
                this.interactiveMessage.template.interactive.header = { type: 'text', text: text }
                break;
            case 'template':
                WhatsAppTemplateUtils.setHeaderTextComponent(this.template.template, text)
                break;
        }
    },

    setHeader<T extends WhatsAppTemplateHeader>(format: WhatsAppTemplateHeaderFormat) {
        switch(this.currentStateType) {
            case 'interactive': {
                let interactiveHeader: Header | undefined = undefined
                switch(format) {
                    case 'STICKER':
                    case 'IMAGE':
                        interactiveHeader = { type: 'image', image: { link: '' } }
                        break;
                    case 'VIDEO':
                        interactiveHeader = { type: 'video', video: { link: '' }}
                        break;
                    case 'DOCUMENT':
                        interactiveHeader = { type: 'document', document: { link: '', fileName: '' }}
                        break;
                    case 'TEXT':
                    default:
                        interactiveHeader = { type: 'text', text: '' }
                }
                this.interactiveMessage.template.interactive.header = interactiveHeader
                break;
            }
            case 'template': {
                const header = format === 'TEXT' ? 
                    { type: 'HEADER', format: 'TEXT', text: '' } as WhatsAppTemplateHeader : 
                    { type: 'HEADER', format: format || 'IMAGE', example: { header_handle: [] } } as WhatsAppTemplateHeader

                WhatsAppTemplateUtils.setHeaderComponent(this.template.template, header!)
                break;
            }
        }
    },

    removeHeader() {
        switch(this.currentStateType) {
            case 'interactive':
                this.interactiveMessage.template.interactive.header = undefined
                break;
            case 'template':
                WhatsAppTemplateUtils.removeComponent(this.template.template, 'HEADER')
                break;
        }
    },

    getFooter<T extends WhatsAppTemplateFooter>(): T | undefined {
        switch(this.currentStateType) {
            case 'interactive':
                return this.interactiveMessage.template.interactive.footer as T
            case 'template':
                return WhatsAppTemplateUtils.getFooterComponent(this.template.template.components) as T
        }
    },

    setFooter<T extends WhatsAppTemplateHeader>() {
        switch(this.currentStateType) {
            case 'interactive':
                if(this.interactiveMessage.template.interactive) {
                    this.interactiveMessage.template.interactive.footer = { text: '' }
                }
                break;
            case 'template':
                WhatsAppTemplateUtils.setFooterComponent(this.template.template, { type: 'FOOTER', text: ''})
                break;
        }
    },

    setFooterText<T extends WhatsAppTemplateHeader>(text: string) {
        switch(this.currentStateType) {
            case 'interactive':
                if(this.interactiveMessage.template.interactive) {
                    this.interactiveMessage.template.interactive.footer = { text: text }
                }
                break;
            case 'template':
                WhatsAppTemplateUtils.setFooterText(this.template.template, text)
                break;
        }
    },

    removeFooter<T extends WhatsAppTemplateHeader>() {
        switch(this.currentStateType) {
            case 'interactive':
                if(this.interactiveMessage.template.interactive) {
                    this.interactiveMessage.template.interactive.footer = undefined
                }
                break;
            case 'template':
                WhatsAppTemplateUtils.removeComponent(this.template.template, 'FOOTER')
                break;
        }
    },

    setText(text: string) {
        switch(this.currentStateType) {
            case 'interactive': {
                let t = this.interactiveMessage.template.text
                if(!t) {
                    t = {
                        type: 'text',
                        text: { body: '' }
                    }
                }

                t.text.body = text
                this.interactiveMessage.template.text = t
                break;
            }
            case 'template':
                WhatsAppTemplateUtils.setTextForTextComponent(this.template.template, text)
                break;
        }
    },

    getImage() {
        switch(this.currentStateType) {
            case 'interactive':
                break;
            case 'template':
                break;
        }
    },

    usingButtons() {
        switch(this.currentStateType) {
            case 'interactive':
                return this.interactiveMessage.buttons.quickReply.length > 0 || this.interactiveMessage.buttons.outlink !== undefined
                break;
            case 'template':
                return WhatsAppTemplateUtils.getButtonComponent(this.template.template.components).buttons.length > 0
                break;
        }
    },

    getAllCtaButtons(): WhatsAppTemplateButton[] {
        switch(this.currentStateType) {
            case 'interactive':
                return this.interactiveMessage.buttons.outlink ? [this.interactiveMessage.buttons.outlink] : []
            case 'template':
                return WhatsAppTemplateUtils.getAllButtons(this.template.template.components)
            default:
                return []
        }
    },

    getAllQRButtons(): (WhatsAppTemplateButton | QuickReplyButton)[] {
        switch(this.currentStateType) {
            case 'interactive':
                return this.interactiveMessage.buttons.quickReply
            case 'template':
                return WhatsAppTemplateUtils.getAllButtons(this.template.template.components)
            default:
                return []
        }
    },

    getButtons<T extends WhatsAppTemplateButton[] | QuickReplyButton[]>(type?: WhatsAppTemplateButtonType): T | [] {
        switch(this.currentStateType) {
            case 'interactive':
                return (type === 'QUICK_REPLY' ? this.interactiveMessage.buttons.quickReply : type === 'URL' ? ( this.interactiveMessage.buttons.outlink ? [this.interactiveMessage.buttons.outlink] : []) : []) as T
            case 'template':
                return WhatsAppTemplateUtils.getByButtonType(this.template.template.components, type!) as T
            default:
                return []
        }
    },

    getButtonText(button: WhatsAppTemplateButton | QuickReplyButton) {
        switch(button.type) {
            case 'reply':
                return button.reply.title
            case 'COPY_CODE':
                return button.example
            default:
                return button.text
        }
    },

    getQrButtonTextModel(button: WhatsAppTemplateQuickReplyButton | QuickReplyButton): string {
        switch(this.currentStateType) {
            case 'interactive':
                return (button as QuickReplyButton).reply?.title || ''
            case 'template': {
                const b = button as WhatsAppTemplateQuickReplyButton
                const vv =  WhatsAppTemplateUtils.getByButtonType<WhatsAppTemplateQuickReplyButton[]>
                (this.template.template.components, b.type)?.find((buttonInArr: WhatsAppTemplateQuickReplyButton) => buttonInArr === button)?.text || ''

                return vv
            }
            default:
                return ''
        }
    },

    addLinkButton() {
        switch(this.currentStateType) {
            case 'interactive':
                this.interactiveMessage.buttons.outlink = { text: '', url: '', type: 'URL' }
                break;
            case 'template':
                WhatsAppTemplateUtils.setByButtonType(this.template.template, {
                    type: 'URL',
                    url: '',
                    text: ''
                })
                break;
        }
    },

    addPhoneButton() {
        switch(this.currentStateType) {
            case 'interactive':
                throw new Error('Not Supported')
            case 'template':
                WhatsAppTemplateUtils.setByButtonType(this.template.template, {
                    type: 'PHONE_NUMBER',
                    phone_number: '',
                    text: ''
                })
                break;
        }
    },

    addCopyCodeButton() {
        switch(this.currentStateType) {
            case 'interactive':
                throw new Error('Not Supported')
            case 'template':
                WhatsAppTemplateUtils.setByButtonType(this.template.template, {
                    type: 'COPY_CODE',
                    example: '',
                })
                break;
        }
    },

    removeButton(button: WhatsAppTemplateButton | QuickReplyButton) {
        switch(this.currentStateType) {
            case 'interactive':
                switch(button.type) {
                    case 'URL':
                        this.interactiveMessage.buttons.outlink = undefined
                        break;
                    case 'QUICK_REPLY':
                    case 'reply':
                        this.interactiveMessage.buttons.quickReply = this.interactiveMessage.buttons.quickReply.filter(qr => qr.reply.id !== (button as QuickReplyButton).reply.id)
                        break;
                }
                break;
            case 'template':
                WhatsAppTemplateUtils.removeByType(this.template.template, button as WhatsAppTemplateButton)
                break;
        }
    },

    addQrButton(text = '') {
        switch(this.currentStateType) {
            case 'interactive':
                if (this.interactiveMessage.buttons.quickReply.length < 3) {
                    this.interactiveMessage.buttons.quickReply.push({ reply: { id: uuidv4(), title: text, }, type: 'reply' })
                }
                break;
            case 'template':
                WhatsAppTemplateUtils.setByButtonType(this.template.template, {
                    type: 'QUICK_REPLY',
                    text: text
                })
                break;
        }
    },

    removeQrButton(button: WhatsAppTemplateButton | QuickReplyButton) {
        switch(this.currentStateType) {
            case 'interactive': {
                const buttonToRemove = button as QuickReplyButton
                const qrButton = this.interactiveMessage.buttons.quickReply.find(b => b.reply.title === buttonToRemove.reply.title)

                if(qrButton) {
                    const index = this.interactiveMessage.buttons.quickReply.indexOf(qrButton)
        
                    if(index > -1) {
                        this.interactiveMessage.buttons.quickReply.splice(index, 1)
                    }
                }
                break;
            }
            case 'template':
                break;
        }
    },

    handleTextChange(button: WhatsAppTemplateButton | QuickReplyButton, text: string) {
        const canChange = qrButtonValidator(text)

        if(canChange) {
            switch(this.currentStateType) {
                case 'interactive': {
                    const b = button as QuickReplyButton
                    const index = this.interactiveMessage.buttons.quickReply.indexOf(b)
                    
                    if(index) {
                        b.reply.title = text
                        this.interactiveMessage.buttons.quickReply[index] = b
                    }
                    break;
                }
                case 'template':
                    WhatsAppTemplateUtils.setButtonText(this.template.template, button as WhatsAppTemplateButton, text)
                    break;
            }
        }
    },

    listButtonString(str: string) {
        switch(this.currentStateType) {
            case 'interactive':
                this.interactiveMessage.buttons.list.button = str
                break;
        }
    },

    addSection() {
        switch(this.currentStateType) {
            case 'interactive':
                this.interactiveMessage?.buttons.list.sections.push({ title: 'Temp Title', rows: [{ id: uuidv4(), title: "I'm a row", description: 'Yes' }]})
                break;
        }
    },

    removeSection(section: ListSection) {
        switch(this.currentStateType) {
            case 'interactive': {
                const index = this.interactiveMessage?.buttons.list.sections.indexOf(section)

                if(index > -1) {
                    this.interactiveMessage?.buttons.list.sections.splice(index, 1)
                }    
                break;
            }
        }
    },

    removeAsset(asset: Asset) {
        const index = this.assets.indexOf(asset)

        if(index > -1) {
          this.assets.splice(index, 1)
        }  

       const assetIndexTemplate = this.header?.example?.header_handle?.indexOf(asset.url) || -1

       if(assetIndexTemplate > -1) {
          this.header?.example?.header_handle?.splice(assetIndexTemplate, 1)
       }
    },

    async uploadAsset(formData: FormData): Promise<string> {
        const snackbarStore = useSnackbarStore();


        const path = await this.buildPath(`assets/templates`)
  
        const response = await workspace
            .post(path, formData, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } })
            .catch((e) => {
                const split = e.response.data.split('::')

                snackbarStore.addError(split[0], split[1])
            });

        return response?.data?.assetUrl
    },

    async buildPath(route: string) {
        this.currentWorkspaceId = await CurrentWorkspace.getWorkspace(this.currentWorkspaceId)
        return CurrentWorkspace.buildPath(route, this.currentWorkspaceId)
    },

    processTemplate() {
        this.template.template.name = this.templateName
    }
  },
})

export const DEFAULT_INTERACTIVE_MESSAGE = {
    messaging_product: 'whatsapp',
    recipient_type: 'individual',
    type: 'interactive'
}
