import { App } from "vue";

interface JuneAnalyticsOptions {
  writeKey: string
}

export default {
  install: (app: App, options: JuneAnalyticsOptions) => {
    if (!window) return;

    window.analytics = {};
    function juneify(writeKey: string) {
      window.analytics._writeKey = writeKey;
      const script = document.createElement('script');
      script.type = 'application/javascript';
      script.onload = function () {
        window.analytics.page();

        app.provide('analytics', window.analytics)
      };
      script.src = 'https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js';
      const first = document.getElementsByTagName('script')[0];
      first?.parentNode?.insertBefore(script, first);
    }

    juneify(options.writeKey);
  },
}
