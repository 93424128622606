import workspace from '@/plugins/axios/workspace'
import { RevenueAttributionDto } from '@/types/revenue-attribution.types'

interface SumResponse {
  data: {
    sum: number
    cost: number 
  }
}

interface FetchAllResponse {
  data: RevenueAttributionDto[],
}

export const revenueAttributionService = {
  async fetchSum(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<SumResponse>(`${workspaceId}/revenue-attribution/sum?from=${fromParam}&to=${toParam}`);
  },

  fetchAll(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<FetchAllResponse>(`${workspaceId}/revenue-attribution?from=${fromParam}&to=${toParam}`);
  },

}
