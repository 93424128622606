interface State {
  error?: {
    status:  number,
    message: string,
  }
} 

export const useErrorStore = defineStore('error', {
  state: (): State => ({
    error: undefined,
  })
});
