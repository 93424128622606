import workspace from '@/plugins/axios/workspace'
import { AssignedChatFilter } from '@/types/inbox.types'
import { CurrentWorkspace } from '@/utils/current-workspace'
import { useSnackbarStore } from './useSnackbarStore'
import { notEmpty } from '@/utils/notEmpty'
import { QuickResponse } from '@/types/template.types'
import { quickResponseSanitizer } from '@/services/agent-preferences/quickResponseSanitizer'
import { NotificationPreferences } from '@/types/agent-preferences.types'
import { sanitizeNotificationPreferences } from '@/services/agent-preferences/sanitizeNotificationPreferences'

export interface AgentInboxOption {
  filter: AssignedChatFilter
  id?: string
}

export interface InboxPreferences {
  defaultFilter: AgentInboxOption
}

interface State {
  pinnedFeeds: string[]
  inboxPreferences: InboxPreferences | undefined
  currentWorkspaceId: string | undefined
  quickResponses: QuickResponse[];
  notificationPreferences: NotificationPreferences | undefined;
}

export type FeedPreferencesAction = 'pin' | 'unpin'

export const useAgentPreferencesStore = defineStore('agentPreferencesStore', {
  state: (): State => ({
    pinnedFeeds: [],
    currentWorkspaceId: undefined,
    inboxPreferences: undefined,
    quickResponses: [],
    notificationPreferences: undefined,
  }),
  getters: {
    quickResponseValues(state) {
      return Object.values(state.quickResponses).filter(notEmpty);
    },
    isAnyNotificationEnabled(state) {
      if (!state.notificationPreferences?.web) return false;

      const { feedNotifications, messageNotifications } = state.notificationPreferences.web;

      return feedNotifications.all
        || feedNotifications.defaultFilter
        || messageNotifications.all
        || messageNotifications.defaultFilter;
    },
  },
  actions: {
    async fetchAgentPreferences() {
      const path = await this.buildPath('agent-preferences')
      const { data } = await workspace.get(path, { withCredentials: true })  
      const { agentPreferences } = data

      this.$patch({
        pinnedFeeds: agentPreferences.pinnedFeeds,
        inboxPreferences: agentPreferences.inboxPreferences,
        quickResponses: quickResponseSanitizer(agentPreferences?.quickResponses),
        notificationPreferences: sanitizeNotificationPreferences(agentPreferences?.notifications),
      });

      return agentPreferences;
    },

    async getDefaultInboxFilter(): Promise<InboxPreferences['defaultFilter'] | undefined> {
      if (this.inboxPreferences) {
        return this.inboxPreferences.defaultFilter;
      }

      const { inboxPreferences } = await this.fetchAgentPreferences();
      this.inboxPreferences = inboxPreferences

      return inboxPreferences?.defaultFilter;
    },

    async takeActionOnFeed(feedId: string, action: FeedPreferencesAction) {
        const path = await this.buildPath(`agent-preferences/feed/${feedId}/${action}`)
        const { data } = await workspace.get(path, { withCredentials: true })  
        const { agentPreferences } = data
  
        this.pinnedFeeds = agentPreferences.pinnedFeeds
    },

    async setDefaultInbox(val: AgentInboxOption) {
      const path = await this.buildPath(`agent-preferences/settings/inbox`)
      const { data } = await workspace.post(path, val, { withCredentials: true })  
      const { agentPreferences } = data

      this.inboxPreferences = agentPreferences.inboxPreferences
    },

    async saveQuickResponse(quickResponse: string) {
      const path = await this.buildPath(`agent-preferences/quick-responses`);
      const snackbarStore = useSnackbarStore();

      const response = await workspace.post(path, { quickResponse }, { withCredentials: true }).catch(snackbarStore.handleError)

      if (response) {
        snackbarStore.addSuccess('Message saved as quick response');

        this.quickResponses = quickResponseSanitizer(response.data?.agentPreferences?.quickResponses);
      }
    },

    async saveNotificationPreferences(isEnabled: boolean) {
      const snackbarStore = useSnackbarStore();
      const path = await this.buildPath(`agent-preferences/settings/notifications`);

      if (!this.notificationPreferences) throw Error('Notification preference not loaded');

      const newNotificationPreferences: NotificationPreferences = {
        ...this.notificationPreferences,
        web: {
          ...this.notificationPreferences.web,
          feedNotifications: {
            defaultFilter: isEnabled,
            all: isEnabled,
          },
          messageNotifications: {
            defaultFilter: isEnabled,
            all: isEnabled,
          },
        },
      };
      
      await workspace.post(path, newNotificationPreferences, { withCredentials: true }).catch(snackbarStore.handleError)

      this.notificationPreferences = newNotificationPreferences;
    },

    async deleteQuickResponse(quickResponseId: string) {
      const path = await this.buildPath(`agent-preferences/quick-responses/${quickResponseId}`);
      const snackbarStore = useSnackbarStore();

      const response = await workspace.delete(path, { withCredentials: true }).catch(snackbarStore.handleError)

      if (response) {
        snackbarStore.addSuccess('Quick response deleted');

        this.quickResponses = quickResponseSanitizer(response.data?.agentPreferences?.quickResponses);
      }
    },

    async buildPath(route: string) {
      this.currentWorkspaceId = await CurrentWorkspace.getWorkspace(this.currentWorkspaceId)
      return CurrentWorkspace.buildPath(route, this.currentWorkspaceId)
    },
  },
})
