import { featuresService } from "@/services/features/featuresService";
import { useWorkspaceStore } from "./useWorkspaceStore"
import { useSnackbarStore } from "./useSnackbarStore";
import { Features } from "@/types/features.types";

interface FeaturesStoreState {
  features: Features | undefined;
}

export const useFeaturesStore = defineStore('features', {
  state: (): FeaturesStoreState => ({
    features: undefined,
  }),
  actions: {
    async fetchFeatures() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();
      const workspaceId = await workspaceStore.getWorkspaceId();

      const features = await featuresService.fetchFeatures(workspaceId).catch(snackbarStore.handleError);

      if (features) {
        this.features = features;
      }
    },
  },
})
