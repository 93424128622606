<script setup lang="ts">
import { SnackbarMessageType } from '@/types/snackbar.types';
import { VProgressCircular } from 'vuetify/lib/components/index.mjs';

interface Props {
  type: SnackbarMessageType,
  title: string
  message: string
  persistent?: boolean
}

interface Emits {
  (e: 'close'): void;
}

defineProps<Props>()
defineEmits<Emits>()

const icons: Record<Props['type'], string> = {
  success: 'mingcute:check-circle-fill',
  error: 'mingcute:close-circle-fill',
  warning: 'mingcute:warning-fill',
  info: 'mingcute:album-line',
}

const iconColors: Record<Props['type'], string> = {
  success: '#57A76D',
  error: '#FF5A65',
  warning: '#f9e154',
  info: '#6A8CD9',
}
</script>

<template>
  <div
    class="snackbar"
    :class="{ [type]: true }"
  >
    <div class="snackbar-inner d-flex">
      <VProgressCircular
        v-if="persistent"
        size="25"
        width="2"
        class="snackbar-icon"
        indeterminate
      />
      <VIcon 
        v-else
        :icon="icons[type]" 
        :color="iconColors[type]"
        class="snackbar-icon"
        size="25" 
      />
      <div class="flex-fill">
        <div class="snackbar-title">
          {{ title }}
        </div>
        <div
          v-if="title && message"
          class="snackbar-separator"
        />
        <div class="snackbar-message">
          {{ message }}
        </div>
      </div>
      <VBtn
        v-if="!persistent"
        rounded
        size="25"
        color="white"
        class="snackbar-close"
        :ripple="false"
        @click="$emit('close')"
      >
        <VIcon 
          icon="mingcute:close-line" 
          color="#000" 
          size="20" 
        />
      </VBtn>
    </div>
  </div>
</template>

<style scoped>
.snackbar {
    position: relative;
    border: 2px solid transparent;
    border-radius: 16px;
    background-clip: padding-box;
    padding: 2px;
    min-width: 250px;
    max-width: 450px;
    margin-bottom: 10px;
    overflow: hidden;
    transform: translateY(0);
    transition: all ease-in 200ms;
    max-height: 100vh;
    opacity: 1;
}

.snackbar:last-of-type {
  margin-bottom: 0;
}

.snackbar::after {
  position: absolute;
  top: -2px; bottom: -2px;
  left: -2px; right: -2px;
  content: '';
  z-index: -1;
  border-radius: 16px;
}

.snackbar.error::after {
  background: linear-gradient(150deg, rgba(255,90,101,1) 0%, rgba(215,215,215,1) 60%, rgba(215,215,215,1) 100%);
}

.snackbar.info::after {
  background: linear-gradient(150deg, #6A8CD9 0%, rgba(215,215,215,1) 60%, rgba(215,215,215,1) 100%);
}

.snackbar.success::after {
  background: linear-gradient(150deg, rgba(87,167,109,1) 0%, rgba(215,215,215,1) 60%, rgba(215,215,215,1) 100%);
}

.snackbar.warning::after {
  background: linear-gradient(150deg, #f9e154 0%, rgba(215,215,215,1) 60%, rgba(215,215,215,1) 100%);
}

.snackbar-inner {
  border-radius: 11px;
  background: var(--Neutral-Colors-100, #FFF);
  padding: 16px;
}

.snackbar-icon {
  margin-right: 10px;
}

.snackbar-close {
  margin-top: -10px;
  margin-right: -10px;
}

.snackbar-title {
  margin-top: 3px;
  font-size: 14px;
  line-height: 18px;
  color: #1b1b1b;
}

.snackbar-separator {
  height: 2px;
}

.snackbar-message {
  font-size: 10px;
  color: #474747;
  white-space: pre-wrap;
}

.snackbar-leave-active,
.snackbar-enter-active {
  transition: all ease-in 200ms,
              opacity 200ms 100ms,
              max-height 300ms 100ms;
}

.snackbar-enter-from {
  opacity: 0;
  transform: translateY(50%);
}

.snackbar-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
