import { useSnackbarStore } from "@/stores/useSnackbarStore"
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export type OnDataFunction = (event: MessageEvent<any>) => Promise<boolean>

export class RequestService {
    axios: AxiosInstance
    useAuth: boolean
  
    constructor(axios: AxiosInstance, useAuth: boolean) {
      this.axios = axios
      this.useAuth = useAuth
      if(this.useAuth) {
        axios.defaults.headers.common['Authorization'] = this.getSessionCookie();
      }
    }

    static create(axios: AxiosInstance, useAuth: boolean) {
        return new RequestService(axios, useAuth)
    }
  
    async post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<any, any>> {  
        return await this.axios.post(url, data, config);
    }
    async get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T, any>> {
        return await this.axios.get(url, config);
    }
  
    async patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<any, any>> {
        return await this.axios.patch(url, data, config);
    }
  
    async delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<any, any>> {
        return await this.axios.delete(url, config);
    }

    private getSessionCookie(): string {
        const value = `; ${document.cookie}`
        const parts = value.split(`; __session=`)
        if (parts.length === 2) {
            return parts.pop()?.split(';').shift() || ''
        } else {
            return ''
        }
    }

    websocket(url: string): WebSocket {
        const ws = new WebSocket(url, [
            'Authorization', this.getSessionCookie()
        ]);

        return ws
    }
  }
