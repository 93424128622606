import { DeviceNotificationPreferences, NotificationPreferences } from "@/types/agent-preferences.types";
import { Expect } from "@/utils/Expect";

const sanitizeDeviceNotification = (data: any): DeviceNotificationPreferences => {
  return {
    feedNotifications: {
      defaultFilter: Expect.boolean(data?.feedNotifications?.defaultFilter),
      all: Expect.boolean(data?.feedNotifications?.all),
    },
    messageNotifications: {
      defaultFilter: Expect.boolean(data?.messageNotifications?.defaultFilter),
      all: Expect.boolean(data?.messageNotifications?.all),
    },
    broadcastNotifications: {
      started: Expect.boolean(data?.broadcastNotifications?.started),
      completed: Expect.boolean(data?.broadcastNotifications?.completed),
    },
  }
}

export const sanitizeNotificationPreferences = (data?: any): NotificationPreferences  => {
  return {
    web: sanitizeDeviceNotification(data?.web),
    mobile: sanitizeDeviceNotification(data?.mobile),
  }
};
