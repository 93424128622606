interface LayoutStoreState {
  isVerticalNavExpanded: boolean;
}

export const useLayoutStore = defineStore('layout', {
  state: (): LayoutStoreState => ({
    isVerticalNavExpanded: false,
  }),
});

