<script lang="ts" setup>
import wavingHand from '@/assets/waving-hand-single-loop.gif'
import { useT } from '@/locales/useT';

const t = useT();

const greetingText = computed(() => {
  const d = new Date()

  if (d.getHours() < 12) {
    return t('Good Morning');
  }

  if (d.getHours() >= 12 && d.getHours() < 18) {
    return t('Good Afternoon');
  }

  return t('Good Evening');
});

const headerImage = {
  url: wavingHand,
  alt: '',
  width: 40,
};
</script>

<template>
  <PageHeader
    :title="greetingText"
    :image="headerImage"
  />
</template>
