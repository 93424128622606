<template>
  <div class="app-section">
    <div
      v-if="$slots.title"
      class="app-section-title"
    >
      <slot name="title" />
    </div>
    <div class="app-section-body">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.app-section {
  border-radius: 21px;
  border: 1px solid var(--Neutral-Colors-400, #D7D7D7);
  background: var(--Neutral-Colors-100, #FFF);
  padding: 21px;
  display: flex;
  flex-direction: column;
}

.app-section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  color: var(--Text-Black, #1B1B1B);
  font-feature-settings: 'clig' off, 'liga' off;
  border-bottom: 1px solid #AFAFAF;
  padding-bottom: 18px;
  margin-bottom: 18px;
  /* Tool styling/Frame title */
  font-family: "Plus Jakarta Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
