import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import router from '@/router/index'
import { RequestService } from './requestService'

interface PatchError {
  error: string
  message: string
  statusCode: number
}

const workspaceAxios = axios.create({
  baseURL: import.meta.env.VITE_API_WORKSPACE_ENDPOINT,
  headers: {
    Accept: "application/json",
  },
});

workspaceAxios.interceptors.response.use(
  (response) => { return response },
  (error: AxiosError<PatchError>) => {
    if (error.response) {
    const { status } = error.response
    const sessionMissing = status === 401 || status === 403
    if (sessionMissing) {
        router.push({ path: '/login' })
      }
    }

    return Promise.reject(error)
  }
)

const workspace = RequestService.create(workspaceAxios, true)

export default workspace;
