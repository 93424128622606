<script lang="ts" setup>

interface Props {
  value?: Date
  multiple?: boolean,
  mode?: 'date' | 'dateTime',
  header?: string,
  is24hr?: boolean,
  disabled?: boolean,
  minDate?: Date,
}

interface Emits {
  (e: 'update', value: Date): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const valueRef = ref(props.value);

watchEffect(() => {
  valueRef.value = props.value;
});

const handleDateChange = (newDate: Date) => {
  const updatedDate = props.value
      ? new Date(props.value)
      : new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0));

  if (props.value) {
    updatedDate.setFullYear(newDate.getFullYear());
    updatedDate.setMonth(newDate.getMonth());
    updatedDate.setDate(newDate.getDate());
  }

  emits('update', updatedDate);
}
</script>

<template>
  <VDatePicker
    v-model="valueRef"
    width="250"
    max-width="250"
    hide-header
    :multiple="multiple"
    :mode="mode"
    :header="header"
    :is24hr="is24hr"
    :disabled="disabled"
    :min-date="minDate"
    class="app-date-picker"
    @update:model-value="handleDateChange"
  />
  <div
    v-if="mode === 'dateTime'"
    class="d-flex align-center justify-center pb-3"
  >
    <AppTimeInput
      :value="value"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<style scoped>
  .app-date-picker {
    width: 250 !important;
  }

  .app-date-picker :deep(.v-date-picker-month) {
    min-width: 250px;
  }

  .app-date-picker :deep(.v-date-picker-month__days) {
    gap: 2px;
  }

  .app-date-picker :deep(.v-date-picker-month__day) {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  .app-date-picker :deep(.v-date-picker-month__day .v-btn__content) {
    font-size: 12px;
  }

  .app-date-picker :deep(.v-date-picker-month__day .v-btn) {
    height: 20px;
  }

  .app-date-picker :deep(.v-date-picker-controls) {
    padding: 12px 12px;

  }

  .app-date-picker :deep(.v-date-picker-controls .v-btn) {
    height: 30px;
    padding: 0 6px;
    font-size: 12px;
  }

  .app-date-picker :deep(.v-date-picker-controls .v-btn--icon) {
    width: 30px;
    padding: 0;
  }

  .app-date-picker :deep(.v-date-picker-years__content),
  .app-date-picker :deep(.v-date-picker-months__content) {
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    padding-bottom: 12px;
    gap: 8px;
  }
</style>
