<script lang="ts" setup>
import { useErrorStore } from '@/stores/useErrorStore';

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
</script>
<template>
  <div
    v-if="error"
    class="pa-6"
  >
    <h1>{{ $t('error_boundary.title') }}</h1>
    <h2>{{ $t('error_boundary.code', { code: error.status }) }}</h2>
    <p>{{ error.message }}</p>
    <VBtn
      @click="$router.go(-1)"
    >
      {{ $t('Go back') }}
    </VBtn>
    <VBtn
      variant="plain"
      :ripple="false"
      @click="error = undefined" 
    >
      {{ $t('Try again') }}
    </VBtn>
  </div>
  <slot v-else />
</template>
