import { useI18n } from 'vue-i18n'

import enUS from './en-US.json'

export const useT = () => {
  const { t } = useI18n<{
    message: typeof enUS,
  }>({
    useScope: 'global',
    inheritLocale: true,
  })

  return t;
}
