import { RouteRecordRaw } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'

import pages from '~pages'

const routes: RouteRecordRaw[] = [
    { path: '/workspace', redirect: '/workspace/opt-ins' },
    { path: '/settings', redirect: '/settings/profile'  },
    { path: '/templates', redirect: '/settings/templates'  },
    { path: '/audiences', redirect: '/audience'  },
    ...setupLayouts(pages),
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ]
  
  export default routes
  