<script lang="ts" setup>
import { formatDate } from '@/@core/utils/formatters';

interface Props {
  from?: Date;
  to?: Date;
  placeholder?: string;
}

interface PresetOption {
  from?: Date,
  to?: Date,
  label: string,
}

interface Emits {
  (e: 'update', event: { from: Date, to: Date }): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const today = new Date();

const last7DaysFrom = new Date();
last7DaysFrom.setDate(last7DaysFrom.getDate() - 7);

const last30DaysFrom = new Date();
last30DaysFrom.setDate(last30DaysFrom.getDate() - 30);

const lastMonthFrom = new Date();
lastMonthFrom.setMonth(lastMonthFrom.getMonth() - 1);
lastMonthFrom.setDate(1);

const lastMonthTo = new Date();
lastMonthTo.setDate(1);
lastMonthTo.setDate(lastMonthTo.getDate() - 1);

const monthToDateFrom = new Date();
monthToDateFrom.setDate(1);

const weekToDateFrom  = new Date();
weekToDateFrom.setDate(weekToDateFrom.getDate() - weekToDateFrom.getDay());

const options: PresetOption[] = [
  {
    label: 'Last 7 days',
    from: last7DaysFrom,
    to: today,
  },
  {
    label: 'Last 30 days',
    from: last30DaysFrom,
    to: today,
  },
  {
    label: 'Last month',
    from: lastMonthFrom,
    to: lastMonthTo,
  },
  {
    label: 'Week to date',
    from: weekToDateFrom,
    to: today,
  },
  {
    label: 'Month to date',
    from: monthToDateFrom,
    to: today,
  },
  {
    label: 'Custom'
  },
];

const fromDateRef = ref<Date | undefined>(props.from);
const toDateRef = ref<Date | undefined>(props.to);
const isMenuOpen = ref(false);

watchEffect(() => {
  fromDateRef.value = props.from;
});

watchEffect(() => {
  toDateRef.value = props.to;
});

const findOption = (fromDate?: Date, toDate?: Date): PresetOption => {
  const custom = options[options.length - 1];

  if (!fromDate || !toDate) return custom;

  const selectedOption = options.find((item) => {
    return (
      item.from?.toDateString() === fromDate?.toDateString() &&
      item.to?.toDateString() === toDate?.toDateString()
    );
  });

  return selectedOption ?? custom;
}

const refOption = computed(() => {
  return findOption(fromDateRef.value, toDateRef.value);
});

const propsOption = computed(() => {
  return findOption(props.from, props.to);
});

const handleCancel = () => {
  isMenuOpen.value = false;
  fromDateRef.value = props.from;
  toDateRef.value = props.to;
};

const handleApply = () => {
  if (!fromDateRef.value || !toDateRef.value) return;

  emits('update', {
    from: fromDateRef.value,
    to: toDateRef.value,
  });

  isMenuOpen.value = false;
};

const handleOption = (item: PresetOption) => {
  fromDateRef.value = item.from;
  toDateRef.value = item.to;
};

const triggerLabel = computed(() => {
  const isNotCustom = propsOption.value.from && propsOption.value.to;

  if (isNotCustom) {
    return propsOption.value.label;
  }

  if (props.from && props.to) {
    return `${formatDate(props.from)} - ${formatDate(props.to)}`;
  }

  return propsOption.value.label;
});
</script>

<template>
  <div>
    <PickerTrigger>
      <CalendarIcon />
      {{ triggerLabel }}
    </PickerTrigger>
    <VMenu
      v-model="isMenuOpen"
      activator="parent"
      :close-on-content-click="false"
      :content-props="{ style: 'background-color: white; overflow: hidden;' }"
    >
      <div>
        <div class="d-flex">
          <ul class="pa-5">
            <li
              v-for="item in options"
              :key="item.label"
              class="pa-1 cursor-pointer"
              :class="item.label === refOption.label && 'selected-date-range-option'"
              @click="handleOption(item)"
            >
              {{ item.label }}
            </li>
          </ul>
          <AppDatePicker
            :value="fromDateRef"
            @update="fromDateRef = $event"
          />
          <AppDatePicker
            :value="toDateRef"
            @update="toDateRef = $event"
          />
        </div>
        <div
          class="d-flex justify-end pb-4 pr-4"
          style="margin-top: -10px"
        >
          <VBtn
            variant="outlined"
            size="small"
            class="mr-2"
            rounded
            @click="handleCancel"
          >
            {{ $t('Cancel') }}
          </VBtn>
          <VBtn
            variant="elevated"
            size="small"
            rounded
            :disabled="!fromDateRef || !toDateRef"
            @click="handleApply"
          >
            {{ $t('Apply') }}
          </VBtn>
        </div>
      </div>
    </VMenu>
  </div>
</template>

<style scoped>
.selected-date-range-option {
  background-color: #F0F0F0;
  border-radius: 4px;
}
</style>
