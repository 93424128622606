<script lang="ts" setup>
import { formatNumber } from '@/@core/utils/formatters';

interface Props {
  newSubscribersCount: number;
  newSubscribersCountPrevious: number;
}

const props = defineProps<Props>();

const increase = computed(() => {
  const diff = props.newSubscribersCount - props.newSubscribersCountPrevious;

  return (diff / props.newSubscribersCountPrevious) * 100;
});

const valueFormatted = computed((): string => {
  return formatNumber(props.newSubscribersCount);
});
</script>

<template>
  <DashboardOverviewField
    :label="$t('New Subscribers')"
    :increase="increase"
  >
    {{ valueFormatted }}
  </DashboardOverviewField>
</template>
