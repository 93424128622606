<script lang="ts" setup>
import { useChatStore } from '@/stores/useChatStore';
import ChatFeedCard from '@/views/apps/chat/ChatFeedCard.vue';

const router = useRouter();
const chatStore = useChatStore();
const { feeds } = storeToRefs(chatStore);

onMounted(() => {
  chatStore.fetchFeeds({ metadataFilter: 'open' });
});

function handleOpen(feedId: string) {
  router.push(`/inbox?feedId=${feedId}`);
}
</script>

<template>
  <div>
    <DashboardFeedListCount :open-conversations-count="feeds.length" />
    <ChatFeedCard
      v-for="item of feeds"
      :key="item.id"
      :feed="item"
      :is-loading="false"
      :is-active="false"
      :is-selected="false"
      :has-selection="false"
      disable-selection
      @open-feed="handleOpen(item.id)"
    />
  </div>  
</template>
