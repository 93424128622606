type AnyValue = any | null | undefined;
type EnumLike = Partial<Record<string, any>>;

export const Expect = {
  string(value: AnyValue): string {
    if (!value) return '';

    return `${value}`;
  },

  array(value: AnyValue): Array<any | never> {
    if (!Array.isArray(value)) return [];

    return value;
  },

  enum<T extends EnumLike>(value: AnyValue, CheckEnum: T): T[keyof T] | undefined {
    const valueKey: keyof T | undefined = Object.keys(CheckEnum).find((key) => {
      return typeof key === 'string' && CheckEnum[key] === value;
    });

    if (valueKey) {
      return CheckEnum[valueKey];
    }
  },

  boolean(value: AnyValue) {
    return Boolean(value);
  },

  integer(value: AnyValue) {
    return parseInt(`${value}`, 10);
  },

  optionalInteger(value: AnyValue) {
    if ([null, undefined, ''].includes(value)) return;

    return parseInt(`${value}`, 10);
  },

  date(value: AnyValue): Date | undefined {
    if (!value) return;

    const date = new Date(value);

    if (isNaN(Number(date))) return;

    return date;
  }
}
