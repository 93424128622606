import axiosApi from "@/plugins/axios/axiosApi"

export class CurrentWorkspace {
    static async fetchId(): Promise<string> {
        const { data }= await axiosApi.get('session/workspace', { withCredentials: true })
        const { currentWorkspaceId } = data as { currentWorkspaceId: string | undefined}
        return currentWorkspaceId || ''
    }

    static buildPath(route: string, workspaceId: string) {
        return `${workspaceId}/${route}`
    }

    static async getWorkspace(workspaceId: string | undefined) {
        return workspaceId || await CurrentWorkspace.fetchId()
    }
}