<script lang="ts" setup>
import { formatNumber } from '@/@core/utils/formatters';

interface Props {
  conversationsCount: number;
  conversationsCountPrevious: number;
}

const props = defineProps<Props>();

const value = computed(() => {
  return formatNumber(props.conversationsCount);
});

const increase = computed(() => {
  const diff = props.conversationsCount - props.conversationsCountPrevious;

  return (diff / props.conversationsCountPrevious) * 100;
});
</script>

<template>
  <DashboardOverviewField
    :label="$t('AI Conversations')"
    :increase="increase"
  >
    {{ value }}
  </DashboardOverviewField> 
</template>
