import { KeyValuePair } from  "@/types/utility.types"
import { ContactPropertyValue } from "@/types/contact-property.types"
import { HoolaInitiatorOrigin } from "@/types/revenue-attribution.types"
import { HoolaChannel } from "@/types/channel.types"

export enum EventOriginType {
    AGENT = 'AGENT',
    WEBHOOK = 'WEBHOOK',
    INTEGRATION = 'INTEGRATION',
    FLOW = 'FLOW',
    EVENT_SOURCE = 'EVENT_SOURCE',
    BROADCAST = 'BROADCAST'
}

export enum ContactEventType {
    TAGS = 'TAGS',
    CONTACT_PROPERTIES = 'CONTACT_PROPERTIES',
    ORDER = 'ORDER',
    SYNC = 'SYNC',
    OPT_IN_STATUS = 'OPT_IN_STATUS',
    SIGNALS = 'SIGNALS',
    BROADCAST = 'BROADCAST',
    TRACKING = 'TRACKING'
}

export const AllContactEventTypes = Object.keys(ContactEventType) as ContactEventType[]

export enum ContactEventSubType {
    TAG_APPLIED = 'TAG_APPLIED',
    TAG_REMOVED = 'TAG_REMOVED',

    CONTACT_PROPERTY_SET = 'CONTACT_PROPERTY_SET',

    ORDER_NEW = 'ORDER_NEW',

    CONTACT_SYNCED = 'CONTACT_SYNCED',

    OPT_IN_STATUS_REJECTED = 'OPT_IN_STATUS_REJECTED',
    OPT_IN_STATUS_GRANTED = 'OPT_IN_STATUS_GRANTED',

    BROADCAST_TEMPLATE_SENT = 'BROADCAST_TEMPLATE_SENT',
    BROADCAST_FLOW_STARTED = 'BROADCAST_FLOW_STARTED',

    SIGNALS_PRODUCT_MENTIONED = 'SIGNALS_PRODUCT_MENTIONED',

    CLICK_TRACKED = 'CLICK_TRACKED'
}

export enum ContactEventChannels {
    Hoola = 'Hoola',
    Klaviyo = 'Klaviyo',
    Shopify = 'Shopify',
    WooCommerce = 'WooCommerce',
    Stripe = 'Stripe',
    Custom = 'Custom'
}

export interface ContactEventData {
    title: string
    description: string
    channel: keyof typeof ContactEventChannels
    metadata: ContactEventMetadata[]
    opts: ContactEventOpts
    additionalData: KeyValuePair<any>
    externalEventId?: string
}

export interface ContactEventMetadata {
    name: string
    value: number
    id?: string
    link?: string
    icon?: string
    meta?: {
        sku?: string | number
        variant?: string
        quantity?: number
    }
}

export interface SignalsData {
    product: string | undefined,
    purchaseIntent: boolean,
    confidence: number,
    isAfterSales: boolean,
    wasProductDiscussed: boolean,
    link: string | undefined,
    followUpMessage: string | undefined
}

export interface ClickEventData {
    context: ClickEventContext
    data: TrackedClick
}

export interface ClickEventContext {
    origin: string,
    originType: HoolaInitiatorOrigin,
    clicks: { externalUserId: string }
}

export interface TrackedClick {
    id: string
    workspace_id: string
    channel?: HoolaChannel
    resource_id: string
    external_user_id: string
    contact_id: string
    ip_address: string
    device: string
    operating_system: string
    browser: string
    country: string
    region: string
    city: string
    lat_long: any
    timezone: string
    created_at: Date
}

export interface ContactEventOpts {
    icon?: string
    link?: string
}

type ContactEventBase =  {
    id: string
    workspace_id: string
    contact_id: string
    origin_id: string
    origin_type: string
    time_uuid: string
    created_at: string
    external_event_id: string
}

export type OrderContactEvent = ContactEventBase & {
    event_type: ContactEventType.ORDER,
    event_subtype: ContactEventSubType.ORDER_NEW,
    event_data: ContactEventData,
}

type OptInContactEvent = ContactEventBase & {
    event_type: ContactEventType.OPT_IN_STATUS,
    event_subtype: ContactEventSubType.OPT_IN_STATUS_REJECTED | ContactEventSubType.OPT_IN_STATUS_GRANTED,
}

type ContactPropertiesContactEvent = ContactEventBase & {
    event_type: ContactEventType.CONTACT_PROPERTIES,
    event_data: {
        name: string,
        value: ContactPropertyValue,
    },
}

type TagsContactEvent = ContactEventBase & {
    event_type: ContactEventType.TAGS,
    event_subtype: ContactEventSubType.TAG_APPLIED | ContactEventSubType.TAG_REMOVED,
    event_data: {
        name: string,
        color: string,
    }
}

type SignalsContactEvent = ContactEventBase & {
    event_type: ContactEventType.SIGNALS,
    event_subtype: ContactEventSubType.SIGNALS_PRODUCT_MENTIONED,
    event_data: SignalsData
}

type BroadcastContactEvent = ContactEventBase & {
    event_type: ContactEventType.BROADCAST,
    event_subtype: ContactEventSubType.BROADCAST_FLOW_STARTED | ContactEventSubType.BROADCAST_TEMPLATE_SENT,
    event_data: { name: string }
}

type ClickContactEvent = ContactEventBase & {
    event_type: ContactEventType.TRACKING,
    event_subtype: ContactEventSubType.CLICK_TRACKED,
    event_data: ClickEventData
}

export type ContactEvent = OrderContactEvent | OptInContactEvent | ContactPropertiesContactEvent | TagsContactEvent | SignalsContactEvent | BroadcastContactEvent | ClickContactEvent
