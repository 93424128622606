<script lang="ts" setup>
import { useAgentStore } from '@/stores/useAgentStore';

const agentStore = useAgentStore();
const { currentAgent } =  storeToRefs(agentStore);

onMounted(() => {
  agentStore.getCurrentAgent();
});

const name = computed(() => {
  return currentAgent.value?.firstName;
});

const avatar = computed(() => {
  return currentAgent.value?.avatar;
});
</script>

<template>
  <AppSection class="dashboard-feed-list pa-5">
    <template #title>
      <i18n-t keypath="Hi, %{name}">
        <template #name>
          <VAvatar
            v-if="avatar"
            :size="25"
            class="mx-2"
          >
            <VImg :src="avatar" />
          </VAvatar>
          {{ name }}
        </template>
      </i18n-t>
    </template>
    <div class="overflow-auto h-100">
      <slot />
    </div>
  </AppSection>
</template>

<style scoped>
.dashboard-feed-list {
  flex: 0 0 320px;
  max-width: 320px;
}

.dashboard-feed-list :deep(.app-section-title) {
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.dashboard-feed-list :deep(.app-text-style-frame-subtitle em) {
  color: #EE6954;
  font-style: normal;
}
</style>
