import workspace from '@/plugins/axios/workspace'

interface SumResponse {
  sum: number
}

export const ordersService = {
  async fetchSum(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<SumResponse>(`${workspaceId}/orders/sum?updated_at_min=${fromParam}&updated_at_max=${toParam}`);
  }
}
