import { initializeApp } from 'firebase/app';
import { MessagePayload, Messaging, getMessaging, getToken, onMessage } from "firebase/messaging";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import workspace from '@/plugins/axios/workspace'

export const useNotifications = () => {
  const handleMessage = useMessageHandler();
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID
  }

  return async () => {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    await registerToken(messaging);

    onMessage(messaging, handleMessage);
  };
}

const registerToken = async (messaging: Messaging): Promise<string> => {
  const workspaceStore = useWorkspaceStore();
  const webAppPublicKey = import.meta.env.VITE_FIREBASE_VAPID_KEY;
  const path = await workspaceStore.buildPath('notifications/register-token');
  const token = await getToken(messaging, { vapidKey: webAppPublicKey });
  
  await workspace.post(path, { token }, { withCredentials: true })  

  return token;
}

export const useMessageHandler = () => {
  const { show, isSupported } =  useWebNotification();

  return (payload: MessagePayload) => {
    if (isSupported.value) {
      show({
        title: payload.notification?.title,
        body: payload.notification?.body,
        icon: payload.notification?.image
      });
    }
  }
}
