export function generateDateLabels(fromDate: Date, toDate: Date, labelGenerator: (date: Date) => string): string[] {
  const labels: string[] = [];

  const currentDate = new Date(fromDate);

  while (currentDate.getTime() < toDate.getTime()) {
    const label = labelGenerator(currentDate);
    const isNewLabel = labels[labels.length - 1] !== label;

    if (isNewLabel) {
      labels.push(label);
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return labels;
}
