<script lang="ts" setup>
import LineChart from '@/views/util/charts/LineChart.vue';
import { useDashboardStore } from '@/stores/useDashboardStore';

const dashboardStore = useDashboardStore();
const { chartLabels, aiSessionsChartData } = storeToRefs(dashboardStore);

const series = computed(() => {
  return [{
    name: 'AI Conversations',
    data: aiSessionsChartData.value,
  }]
})

const chartOptions = {
  responsive: true,
};
</script>

<template>
  <AppPanel class="py-6">
    <div class="px-4">
      <AppText
        is="h3"
        text-style="frame-subtitle"
        text="AI Conversations"
        class="mb-4"
      />
      <LineChart
        :labels="chartLabels"
        :series="series"
        :height="200"
        :chart-options="chartOptions"
      />
    </div>
  </AppPanel>
</template>
