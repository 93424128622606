import axios, { AxiosError } from "axios"
import router from '../index'

interface HoolaError {
    error: string
    message: string
    statusCode: number
  }

export const authMiddleware = () => {
    axios.interceptors.response.use(
      (response) => { return response },
      (error: AxiosError<HoolaError>) => {
        if (error.response) {
          const { status } = error.response
          const sessionMissing = status === 401 || status === 403

          if (sessionMissing) {
            router.push({ path: '/login' })
          }
        }
  
        return Promise.reject(error)
      }
    )
  }