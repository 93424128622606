<script lang="ts" setup>
interface Props {
  color?: 'black' | 'orange';
  modelValue: boolean;
}

interface Emits {
  (e: 'update:modelValue', value: boolean): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const handleToggle = () => {
  emits('update:modelValue', !props.modelValue);
};

const dynamicClasses = computed(() => {
  return {
    'app-checkbox-toggle--checked': props.modelValue,
    'app-checkbox-toggle--orange': props.color === 'orange',
  };
});
</script>

<template>
  <button
    class="app-checkbox-toggle"
    :class="dynamicClasses"
    @mousedown="handleToggle"
  >
    <span
      v-if="!modelValue && color !== 'orange'"
      class="app-checkbox-toggle__empty"
    />
    <VIcon
      v-else-if="modelValue"
      icon="charm:tick"
      color="#F8F8F8"
      :size="16"
    />
  </button>
</template>

<style scoped>
.app-checkbox-toggle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #D7D7D7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-checkbox-toggle--orange {
  border-radius: 5px;
}

.app-checkbox-toggle--checked {
  background-color: #040404;
  border-color: #040404;
}

.app-checkbox-toggle--checked.app-checkbox-toggle--orange {
  background-color: #EE6954;
  border-color: #EE6954;
}

.app-checkbox-toggle__empty {
  width: 12px;
  height: 12px;
  border: 1px solid #D7D7D7;
  display: block;
  border-radius: 2px;
}
</style>
