<script lang="ts" setup>
import { ContactTagDto } from '@/types/contact.types';

interface Props {
  tags: ContactTagDto[];
}

const props = defineProps<Props>();

const supportTags = computed(() => {
  const filteredTags = props.tags.filter(tag => tag.category === 'SUPPORT');

  filteredTags.sort((t1: ContactTagDto, t2: ContactTagDto) => t1.label > t2.label ? 1 : -1)

  return filteredTags;
});

const showTags = computed(() => {
  return supportTags.value.slice(0, 2);
});

const extraTagsCount = computed(() => {
  return supportTags.value.length - 2;
});
</script>

<template>
  <div
    v-if="showTags.length"
    class="overflow-hidden d-flex ga-1"
  >
    <VChip
      v-for="tag in showTags"
      :key="tag.id"
      :color="tag.color"
      variant="flat"
      class="feed-card-support-chip"
    >
      {{ tag.label }}
    </VChip>
    <span
      v-if="extraTagsCount > 0"
      style="display: inline-block;"
    >
      <VChip
        color="#d3d3d3"
        variant="tonal"
      >
        +{{ extraTagsCount }}
      </VChip>
    </span>
  </div>
</template>
