import App from '@/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueCookies from 'vue-cookies';
import { authMiddleware } from './router/auth/middleware'
import VSwatches from 'vue3-swatches'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import { clerkPlugin } from 'vue-clerk'
import { createLocales } from './locales/createLocales'
import VueApexCharts from "vue3-apexcharts";
import * as Sentry from "@sentry/vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
} from 'chart.js'
import juneAnalytics from '@/plugins/june'

import '@/modules/widget/dist/style.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

// Create vue app
const app = createApp(App)

app.use(createLocales());

app.use(clerkPlugin, {
  publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
})

app.use(VueApexCharts);

loadFonts()
authMiddleware()

// Use plugins
app.use(VueCookies)
app.use(vuetify)
app.use(createPinia())
app.use(layoutsPlugin)
app.use(VSwatches)
app.use(VCalendar, {})
app.use(router)

if (import.meta.env.VITE_JUNE_ANALYTICS_WRITE_KEY) {
  app.use(juneAnalytics, { writeKey: import.meta.env.VITE_JUNE_ANALYTICS_WRITE_KEY })  
}

try {
  // Sentry.init({
  //   app,
  //   dsn: "https://d312e7ea63f9a6343200bc0f1022740d@o4506041971769344.ingest.us.sentry.io/4506041974390784",
  //   integrations: [Sentry.browserTracingIntegration({ router })],
  //   environment: import.meta.env.VITE_ENV,
  // });
} catch (err) {
  Sentry.captureException(err);
}

app.mount('#app')
