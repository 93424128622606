import { createI18n } from 'vue-i18n'
import defaultsDeep from 'lodash.defaultsdeep'

import enUS from './en-US.json'
import esES from './es-ES.json'

const getBrowserLanguage = () => {
  if (navigator.language.includes('es')) {
    return 'es-ES';
  }

  return 'en-US';
}

export type MessageSchema = typeof enUS;

export const createLocales = () => {
  const i18n = createI18n<[MessageSchema], 'en-US' | 'es-ES'>({
    legacy: false,
    locale: getBrowserLanguage(),
    fallbackLocale: 'en-US',
    messages: {
      'en-US': enUS,
      'es-ES': defaultsDeep(esES, enUS),
    },
  });

  return i18n;
}
