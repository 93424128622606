<script lang="ts" setup>
import { FeedAction, useChatStore } from '@/stores/useChatStore'

interface Props {
  isPinned: boolean;
  isClosed: boolean;
  feedId: string;
}

const props = defineProps<Props>();

const chatStore = useChatStore();

const feedAction = async (name: FeedAction) => {
  await chatStore.takeActionOnFeed(name, props.feedId)
}
</script>

<template>
  <div class="chat-feed-card-actions">
    <VBtn
      size="30"
      variant="text"
      @click.stop="feedAction(isPinned ? 'Unpin' : 'Pin')"
    >
      <VIcon :icon="isPinned ? 'iconamoon:star-off' : 'iconamoon:star'" />
    </VBtn>
    <VBtn
      size="30"
      variant="text"
      @click.stop="feedAction(isClosed ? 'Open' : 'Close')"
    >
      <VIcon :icon="isClosed ? 'icon-park-outline:back': 'mingcute:close-line'" />
    </VBtn>
  </div>
</template>

<style scoped>
.chat-feed-card-actions {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  border: 0.4px solid var(--Neutral-Colors-400, #D7D7D7);
  background: #FFF;
  display: flex;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
</style>
