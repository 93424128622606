import { QuickResponse } from "@/types/template.types"
import { Expect } from "@/utils/Expect";

export const quickResponseSanitizer = (items: any): QuickResponse[] => {
  if (!items) return [];

  return Object.keys(items).map(key => ({
    id: key,
    message: Expect.string(items[key]),
  }));
}
