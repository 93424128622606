import { JSONContent } from "@tiptap/vue-3";

export const renderJSONContent = (content: JSONContent, toHtml = false): string => {
  if (content.type === 'doc') {
    return renderJSONContentDoc(content, toHtml);
  }

  if (content.type === 'paragraph') {
    return renderJSONContentParagraph(content, toHtml);
  }

  if (content.type === 'heading' && toHtml) {
    return renderJSONContentHeading(content);
  }

  if (content.type === 'image' && toHtml) {
    return renderJSONContentImage(content);
  }

  if (content.type === 'text') {
    return renderJSONContentText(content, toHtml);
  }

  if (content.type === 'variable') {
    return renderJSONContentVariable(content);
  }

  if (content.type === 'bulletList') {
    return renderJSONContentBulletList(content, toHtml);
  }

  if (content.type === 'orderedList') {
    return renderJSONContentOrderedList(content, toHtml);
  }

  if (content.type === 'listItem') {
    return renderJSONContentListItem(content, toHtml);
  }

  if (content.type === 'quickResponse') {
    return renderJSONContentQuickResponse(content);
  }

  if (content.type === 'mention') {
    return renderJSONContentMention(content, toHtml);
  }

  return '';
}

const renderJSONContentDoc = (content: JSONContent, toHtml: boolean): string => {
  return (content.content || []).map(item => renderJSONContent(item, toHtml)).join('').replace(/[\r\n]*$/g, '');
};

const renderJSONContentParagraph = (content: JSONContent, toHtml: boolean): string => {
  const contentString = content.text || (content.content || []).map(item => renderJSONContent(item, toHtml)).join('');

  if (toHtml) {
    const normalizedContent = contentString.trim().length ? contentString : '&nbsp;';

    return `<p>${normalizedContent}</p>`;
  }

  return `${contentString}\n`;
}

const renderJSONContentText = (content: JSONContent, toHtml: boolean): string => {
  let text = content.text || '';

  content.marks?.forEach((mark) => {
    if (mark.type === 'link' && toHtml) {
      const [leading, trimmed, trailing] = trimSplit(text);

      text = `${leading}<a ${propsToAttributes(mark.attrs || {})} >${trimmed}</a>${trailing}`;
    }

    if (mark.type === 'bold') {
      const [leading, trimmed, trailing] = trimSplit(text);

      if (toHtml) {
        text = `${leading}<strong>${trimmed}</strong>${trailing}`;
      } else {
        text = `${leading}*${trimmed}*${trailing}`;
      }

      return;
    }

    if (mark.type === 'underline') {
      const [leading, trimmed, trailing] = trimSplit(text);

      if (toHtml) {
        text = `${leading}<u>${trimmed}</u>${trailing}`;
      } else {
        text = `${leading}${trimmed}${trailing}`;
      }

      return;
    }

    if (mark.type === 'italic') {
      const [leading, trimmed, trailing] = trimSplit(text);

      if (toHtml) {
        text = `${leading}<i>${trimmed}</i>${trailing}`;
      } else {
        text = `${leading}_${trimmed}_${trailing}`;
      }
    }
  });

  return text;
}

const renderJSONContentVariable = (content: JSONContent) => {
  return `{{ ${content.attrs?.id} }}`;
}

const renderJSONContentQuickResponse = (content: JSONContent) => {
  if (content.attrs?.id === 'QUICK_RESPONSE') {
    return `${content.attrs.label}`;
  }

  return '';
}

function renderJSONContentMention(content: JSONContent, toHtml: boolean): string {
  if (!content.attrs) return '';

  const innerContent = `@${content.attrs?.label}`;

  if (!toHtml) return innerContent;

  return `<strong>${innerContent}</strong>`;
}

const trimSplit = (text: string) => {
  const [, leading, trimmedText, trailing] = text.match(/^(\s*)([\s\S]*?)(\s*)$/) ?? Array(4).fill('');

  return [leading, trimmedText, trailing];
}

const renderJSONContentHeading = (content: JSONContent) => {
  const level = content.attrs?.level || 1;

  const innerContent = Array.isArray(content.content) ? content.content.map(item => renderJSONContent(item, true)) : [];

  return `<h${level}>${innerContent.join('')}</h${level}>`
}

const renderJSONContentImage = (content: JSONContent) => {
  const { src, alt, title } = content.attrs || {};

  return `<img src="${src}" alt="${alt}" title="${title}"  />`;
}

const renderJSONContentBulletList = (content: JSONContent, toHtml: boolean) => {
  const innerContent = Array.isArray(content.content) ? content.content.map(item => renderJSONContent(item, toHtml)) : [];

  if (toHtml) {
    return `<ul>${innerContent.join('')}</ul>`;
  } else {
    return `${innerContent.map(item => `- ${item}`).join('\n')}`;
  }
}

const renderJSONContentOrderedList = (content: JSONContent, toHtml: boolean) => {
  const innerContent = Array.isArray(content.content) ? content.content.map(item => renderJSONContent(item, toHtml)) : [];

  if (toHtml) {
    return `<ol>${innerContent.join('')}</ol>`;
  } else {
    return `${innerContent.map((item, index) => `${index + 1}. ${item}`).join('\n')}`;
  }
}

const renderJSONContentListItem = (content: JSONContent, toHtml: boolean): string => {
  const listItem = content.content?.map(item => renderJSONContent(item, toHtml)).join('') ?? '';

  if (toHtml) {
    return `<li>${listItem}</li>`;
  }

  return listItem;
}

const propsToAttributes = (props: Record<string, string | null>): string => {
  const attributes: string[] = []

  Object.keys(props).forEach(key => {
    const value = props[key];

    if (value !== null) {
      attributes.push(`${key}="${value}"`);
    }
  });

  return attributes.join(' ');
}
