<script lang="ts" setup>
import { useSnackbarStore } from '@/stores/useSnackbarStore';

const store = useSnackbarStore();
const { items } = storeToRefs(store);
</script>

<template>
  <div
    class="snackbar-container"
    @mouseenter="store.pauseAll()"
    @mouseleave="store.resumeAll()"
  >
    <TransitionGroup name="snackbar">
      <Snackbar
        v-for="item in items"
        :key="item.id"
        :title="item.title"
        :message="item.message"
        :type="item.type"
        :persistent="item.persistent"
        @close="store.remove(item.id)"
      />
    </TransitionGroup>
  </div>
</template>

<style scoped>
.snackbar-container {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: end;
}
</style>
