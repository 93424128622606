<script lang="ts" setup>
import ChatFeedCardTags from './ChatFeedCardTags.vue';
import { Feed } from '@/types/feed.types';
import { useChatStore } from '@/stores/useChatStore';
import { useAgentStore } from '@/stores/useAgentStore';

interface Props {
  feed: Feed
}

const props = defineProps<Props>();

const chatStore = useChatStore();
const agentStore = useAgentStore();
const { contacts } = storeToRefs(chatStore);

const contact = computed(() => {
  if (!props.feed.contact_id) return;

  return contacts.value.find(contact => props.feed.contact_id === contact.id);
});

const contactName = computed(() => {
  if (contact.value && contact.value.firstName !== '') {
    return `${contact.value.firstName} ${contact.value.lastName}`;
  }

  return props.feed.channel_user_name || props.feed.external_user_id;
});

const typingAgent = computed(() => agentStore.findByIds([props.feed.temporaryState?.id || ''])[0])

const lastContent = computed(() => {
  if (props.feed.temporaryState) {
    switch(props.feed.temporaryState.event) {
      case 'AGENT_TYPING':
        return `${typingAgent.value.firstName}${typingAgent.value.lastName && typingAgent.value.lastName !== '' ? ` ${typingAgent.value.lastName.charAt(0)}` : ''} is typing...`;
      case 'AGENT_RECORDING_AUDIO':
        return ' is recording audio...';
      default:
        return ' is working...';
    }
  }

  return props.feed.last_content;
});
</script>

<template>
  <div
    style="min-width: 0;"
    class="flex-fill"
  >
    <AppText
      text-style="text-bold"
      :text="contactName"
      class="chat-feed-card__name d-block mb-1"
    />
    <div
      class="d-flex flex-column ga-2"
      style="min-height: 48px;"
    >
      <AppText
        text-style="descriptive"
        :text="lastContent"
        class="chat-feed_card__content d-block"
        :class="!contact?.tags.length && 'chat-feed_card__content--double-height'"
      />
      <ChatFeedCardTags
        v-if="contact?.tags.length"
        :tags="contact.tags"
      />
    </div>
  </div>
</template>

<style scoped>
.chat-feed-card__name {
  line-height: 16px;
}

.chat-feed_card__content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-feed_card__content--double-height {
  min-height: 2.75em;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: unset;
}
</style>
