import { WhatsAppTemplate, WhatsAppTemplateBody, WhatsAppTemplateButton, WhatsAppTemplateButtonType, WhatsAppTemplateButtons, WhatsAppTemplateComponentType, WhatsAppTemplateComponents, WhatsAppTemplateFooter, WhatsAppTemplateHeader, WhatsAppTemplateQuickReplyButton, WhatsAppTemplateTextHeader, WhatsAppTemplateUrlButton } from "@/types/whatsapp-template.types";

export class WhatsAppTemplateUtils {
    static getButtonComponent(components: WhatsAppTemplateComponents): WhatsAppTemplateButtons {
        let buttons = components.find(component => component.type === 'BUTTONS') as WhatsAppTemplateButtons

        if(!buttons) {
            buttons = {
                type: 'BUTTONS',
                buttons: []
            }
        }

        return buttons
    }

    static getTextComponent(components: WhatsAppTemplateComponents): WhatsAppTemplateBody | undefined {
        const body = components.find(component => component.type === 'BODY') as WhatsAppTemplateBody
        return body
    }

    static getHeaderComponent(components: WhatsAppTemplateComponents): WhatsAppTemplateHeader | undefined {
        const header = components.find(component => component.type === 'HEADER') as WhatsAppTemplateHeader

        return header
    }

    static getFooterComponent(components: WhatsAppTemplateComponents): WhatsAppTemplateFooter | undefined {
        const header = components.find(component => component.type === 'FOOTER') as WhatsAppTemplateFooter
        return header
    }

    static setHeaderComponent(template: WhatsAppTemplate, component: WhatsAppTemplateHeader) {
        this.removeComponent(template, "HEADER")
        template.components.push(component)
    }

    static setFooterComponent(template: WhatsAppTemplate, component: WhatsAppTemplateFooter) {
        this.removeComponent(template, 'FOOTER')
        template.components.push(component)
    }

    static setFooterText(template: WhatsAppTemplate, text: string) {
        const component = WhatsAppTemplateUtils.getFooterComponent(template.components)
        let insertObject

        if(component) {
            const textComponent = component as WhatsAppTemplateFooter
            WhatsAppTemplateUtils.sliceAndInsert(template.components, component)
            textComponent.text = text
            template.components.push(textComponent)
        } else {
            insertObject = { type: 'FOOTER', text: text } as WhatsAppTemplateFooter
            template.components.push(insertObject)
        }
    }

    static setHeaderTextComponent(template: WhatsAppTemplate, text: string) {
        const component = WhatsAppTemplateUtils.getHeaderComponent(template.components)
        let insertObject

        if(component) {
            const textComponent = component as WhatsAppTemplateTextHeader
            WhatsAppTemplateUtils.sliceAndInsert(template.components, component)
            textComponent.text = text
            template.components.push(textComponent)
        } else {
            insertObject = { type: 'HEADER', format: 'TEXT', text: text } as WhatsAppTemplateTextHeader
            template.components.push(insertObject)
        }
    }

    static removeComponent(template: WhatsAppTemplate, type: WhatsAppTemplateComponentType) {
        const currentComponent = template.components.find(component => component.type === type)
        template.components = template.components.filter(comp => comp !== currentComponent)
    }

    static setTextForTextComponent(template: WhatsAppTemplate, text: string) {
        const component = WhatsAppTemplateUtils.getTextComponent(template.components)
        const body_text = text.indexOf('{{') >= 0 ? ['name'] : undefined;

        if(component) {
            WhatsAppTemplateUtils.sliceAndInsert(template.components, component)
            component.text = text
            if (body_text) {
                component.example = { body_text }
            }

            template.components.push(component)
        } else {
            const insertObject: WhatsAppTemplateBody =  {
                type: 'BODY',
                text,
            };

            if (body_text) {
                insertObject.example = { body_text };
            }

            template.components.push(insertObject)
        }
    }

    static setTextComponent(template: WhatsAppTemplate, component: WhatsAppTemplateBody) {
        WhatsAppTemplateUtils.sliceAndInsert(template.components, component)
        template.components.push(component)
    }

    static getAllButtons(components: WhatsAppTemplateComponents): WhatsAppTemplateButton[] {
        return WhatsAppTemplateUtils.getButtonComponent(components).buttons
    }

    static getAllButtonsSorted(components: WhatsAppTemplateComponents) {
        const SORTING: WhatsAppTemplateButtonType[]  = [
            'URL', 'PHONE_NUMBER', 'COPY_CODE', 'QUICK_REPLY',
        ];

        const buttons = [...WhatsAppTemplateUtils.getAllButtons(components)];

        buttons.sort((a, b) => {
            const indexA = SORTING.indexOf(a.type);
            const indexB = SORTING.indexOf(b.type);

            if (indexA === indexB) {
                return 0;
            }

            if (indexA < indexB) {
                return -1;
            }

            return 1;
        });

        return buttons;
    }

    static getByButtonType<T extends WhatsAppTemplateButton[]>(components: WhatsAppTemplateComponents, type: WhatsAppTemplateButtonType): T | undefined {
        const buttonComponent = WhatsAppTemplateUtils.getButtonComponent(components)
        return WhatsAppTemplateUtils.getButtonsByType(buttonComponent.buttons, type) as T
    }

    static getButtonObjectForType<T>(button: WhatsAppTemplateButton): T {
        return button as T
    }

    static setByButtonType(template: WhatsAppTemplate, button: WhatsAppTemplateButton, text?: string) {
        const buttonComponent = WhatsAppTemplateUtils.getButtonComponent(template.components)
        const buttons = WhatsAppTemplateUtils.getButtonsByType<WhatsAppTemplateButton>(buttonComponent.buttons, button.type)

        if(WhatsAppTemplateUtils.validateButtonInsert(button.type, buttons)) {
            buttonComponent.buttons.push(button)
        }

        WhatsAppTemplateUtils.setButtonComponent(buttonComponent, template)
    }

    static setButtonText(template: WhatsAppTemplate, button: WhatsAppTemplateButton, text: string) {
        const component = WhatsAppTemplateUtils.getButtonComponent(template.components)
        const buttons = WhatsAppTemplateUtils.getButtonsByType<WhatsAppTemplateButton>(component.buttons, button.type)
        let buttonToReplace = buttons.find(b => b === button)

        if(buttonToReplace) {
            buttonToReplace = WhatsAppTemplateUtils.setButtonTextValue(buttonToReplace, text)
            WhatsAppTemplateUtils.sliceAndInsert(buttons, buttonToReplace)

            buttons.push(buttonToReplace)
            component.buttons = buttons

            WhatsAppTemplateUtils.sliceAndInsert(template.components, component)
            template.components.push(component)
            
        }
    }

    static removeByType(template: WhatsAppTemplate, button: WhatsAppTemplateButton) {
        const buttonComponent = WhatsAppTemplateUtils.getButtonComponent(template.components)
        buttonComponent.buttons = buttonComponent.buttons.filter(b => b !== button)

        WhatsAppTemplateUtils.setButtonComponent(buttonComponent, template)
    }

    static transformTextForVariables = (str: string, varsToInsert?: string[]): string => {
        let output = ''
        const variables: string[] = []
        const split = str.split('{{')
        
        for(let i = 0; i < split.length; i++) {
            if(i === 0) {
                output += split[i]
            } else {
                const newSplit = split[i].split('}}')
                variables.push(newSplit[0])
                const insertItem = varsToInsert ? varsToInsert[i - 1] : `{{${i}}}`
                output += `${insertItem}${newSplit[1]}`
            }
        }

        return output
    }


    private static setButtonTextValue(button: WhatsAppTemplateButton, text: string): WhatsAppTemplateButton {
        switch(button.type) {
            case 'COPY_CODE':
                button.example = text
                break
            default:
                button.text = text
                break
        }

        return button
    }

    private static setButtonComponent(buttonComponent: WhatsAppTemplateButtons, template: WhatsAppTemplate) {
        const currentComponent = template.components.find(component => component.type === 'BUTTONS')
        template.components = WhatsAppTemplateUtils.sliceAndInsert(template.components, currentComponent, buttonComponent)
    }

    private static validateButtonInsert(type: WhatsAppTemplateButtonType, arr: any[]) {
        switch(type) {
            case 'QUICK_REPLY':
                return arr.length < WhatsAppTemplateUtils.QR_BUTTON_LIMIT
            case 'URL':
                return arr.length < WhatsAppTemplateUtils.URL_BUTTON_LIMIT
            default:
                return arr.length < WhatsAppTemplateUtils.DEFAULT_BUTTON_LIMIT
        }
    }

    private static cloneButtonComponent(template: WhatsAppTemplate): WhatsAppTemplateButtons {
        return structuredClone(WhatsAppTemplateUtils.getButtonComponent(template.components))
    }

    private static getButtonsByType<T extends WhatsAppTemplateButton>(buttons: WhatsAppTemplateButton[], type: WhatsAppTemplateButtonType): T[] {
        return buttons.filter(button => button.type === type) as T[] || [] as T[]
    }

    private static sliceAndInsert(arr: any, component: any, insert?: any): any[] {
        if(component) {
            const index = arr.indexOf(component)

            if(index > -1) {
                arr.splice(index, 1)
            }    
        }

        if(insert) {
            arr.push(insert)
        }

        return arr
    }

    private static QR_BUTTON_LIMIT = 10
    private static URL_BUTTON_LIMIT = 2
    private static DEFAULT_BUTTON_LIMIT = 1
}
