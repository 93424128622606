import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'
import { authGuard } from './auth/authGuard'
import { useErrorStore } from '@/stores/useErrorStore';

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach(() => {
  const errorStore = useErrorStore();

  errorStore.error = undefined;
});

router.beforeResolve(authGuard)

export default router
