<script lang="ts" setup>
import { useDashboardStore } from '@/stores/useDashboardStore';
import { useMinLoading } from '@/utils/useMinLoading';

interface Props {
  label: string;
  increase?: number;
}

defineProps<Props>();

const dashboardStore = useDashboardStore();
const { isFetchingData, isFetchingPreviousData } = storeToRefs(dashboardStore);

const {
  setLoading: setIsLoadingData,
  getLoading: getIsLoadingData,
} = useMinLoading();

const {
  setLoading: setIsLoadingPreviousData,
  getLoading: getIsLoadingPreviousData,
} = useMinLoading();

watchEffect(() => {
  setIsLoadingData.value = isFetchingData.value;
});

watchEffect(() => {
  setIsLoadingPreviousData.value = isFetchingPreviousData.value;
});
</script>

<template>
  <div class="d-flex flex-column pa-1">
    <AppText
      text-style="main-title"
      class="dashboard-overview-field__title mb-2"
    >
      <LoadingBar
        v-if="getIsLoadingData"
        height="45px"
        width="100px"
      />
      <slot v-else />
    </AppText>
    <AppText
      text-style="text-bold"
      :text="label"
      class
    />
    <template v-if="typeof increase !== 'undefined'">
      <LoadingBar
        v-if="getIsLoadingPreviousData"
        height="15px"
        width="45px"
        class="mt-1"
      />
      <div
        v-else-if="!isNaN(increase)"
        class="mt-1 d-flex align-center dashboard-overview-field__footer"
        :class="increase < 0 && 'dashboard-overview-field__footer--negative'"
      >
        <IncreaseIcon
          :color="increase < 0 ? '#A75757' : '#05C168'"
        />
        <AppText
          text-style="tags"
          color="inherit"
          class="mr-2 ml-1"
          :text="`${Math.round(increase)}%`"
        />
        <AppText
          text-style="tags"
          color="neutral-500"
          text="vs. previous period"
        />
      </div>
    </template>
  </div>
</template>

<style scoped>
.dashboard-overview-field__title {
  font-size: 36px;
}

.dashboard-overview-field__title :deep(small) {
  font-size: 24px;
}

.dashboard-overview-field__footer {
  color: #05C168;
}

.dashboard-overview-field__footer--negative {
  color: #A75757;
}

.dashboard-overview-field__footer--negative svg {
  transform: scaleY(-1);
}
</style>
