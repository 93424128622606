import workspace from '@/plugins/axios/workspace'
import { AiSession } from '@/types/ai.types';

interface AiSessionsOverviewResponse {
  data: {
    channel: string,
    count: number,
  }[]
}

interface AiSessionsResponse {
  data: AiSession[];
}

interface AiResolutionsResponse {
  data: {
    total: number,
    breakdown: {
      resolved: number,
      unresolved: number,
    },
  }
}

interface NewSubscribersCountResponse {
  data: number
}

export const metricsService = {
  async fetchAiSessionsOverview(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<AiSessionsOverviewResponse>(`${workspaceId}/metrics/ai/sessions/overview?updated_at_min=${fromParam}&updated_at_max=${toParam}`);
  },

  async fetchAiSessions(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<AiSessionsResponse>(`${workspaceId}/metrics/ai/sessions?updated_at_min=${fromParam}&updated_at_max=${toParam}`);
  },

  async fetchAiResolutions(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<AiResolutionsResponse>(`${workspaceId}/metrics/ai/resolutions?updated_at_min=${fromParam}&updated_at_max=${toParam}`);
  },

  async fetchNewSubscribersCount(workspaceId: string, fromDate: Date, toDate: Date) {
    const [fromParam] = fromDate.toISOString().split('T');
    const [toParam] = toDate.toISOString().split('T');

    return workspace.get<NewSubscribersCountResponse>(`${workspaceId}/metrics/contacts/subscribers?created_at_min=${fromParam}&created_at_max=${toParam}`);
  }
}
