<script lang="ts" setup>
interface Props {
  attributedRevenue: number;
  cost: number;
}

const props = defineProps<Props>();

const value = computed((): string => {
  const roas = (props.attributedRevenue / 100) / props.cost;
  const rounded = Math.round(roas);

  if (isNaN(rounded)) return '--';

  return `${rounded} X`;
})
</script>

<template>
  <DashboardOverviewField
    :label="$t('Return on WhatsApp Spent')"
  >
    {{ value }}
  </DashboardOverviewField> 
</template>
