import { isToday } from './index'

export const avatarText = (value: string) => {
  if (!value)
    return ''
  const nameArray = value.split(' ')

  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

// TODO: Try to implement this: https://twitter.com/fireship_dev/status/1565424801216311297
export const kFormatter = (num: number) => {
  const regex = /\B(?=(\d{3})+(?!\d))/g

  return Math.abs(num) > 9999 ? `${Math.sign(num) * +((Math.abs(num) / 1000).toFixed(1))}k` : Math.abs(num).toFixed(0).replace(regex, ',')
}

export const getISOShort = (value: Date) => {
  const split = value.toISOString().split('T')
  return split[0]
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Intl.DateTimeFormatOptions} formatting Intl object to format with
 */
export const formatDate = (value: string | Date, formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value)
    return value

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateToHourMinuteTime = (value: Date) => {
  return value.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
}

export const formatToHourMinuteTime = (value: string) => {
  return formatDateToHourMinuteTime(new Date(value));
}

export const roundDateDownToMinute = (date: Date, roundTo = 1) => {
  const ms = 1000 * 60 * roundTo;
  return new Date(Math.round(date.getTime() / ms) * ms);
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value: string | Date, useTimeForShortDate: boolean) => {
  const date = new Date(value)
  let formatting: Record<string, string> = { month: 'short', day: 'numeric' }

  if (isToday(date)) {
    const ms = Date.now() - date.valueOf()
    const minutes  = Math.floor(ms / 60000);
    const seconds  = ((ms % 60000) / 1000).toFixed(0);

    if(minutes < 60) {
      return `${minutes < 1 ? `${seconds} s` : `${minutes} m`}`
    }

    formatting = { hour: 'numeric', minute: 'numeric' }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value)) 
  } else {
    const date = new Date(value)
    const time = useTimeForShortDate ? `, ${new Intl.DateTimeFormat('en-UK', { hour: 'numeric', minute: 'numeric' }).format(date)}` : '' 
    return `${new Intl.DateTimeFormat('en-UK', { month: 'short', day: 'numeric' }).format(date)}${time}`
  }
}

export const prefixWithPlus = (value: number) => value > 0 ? `+${value}` : value

export function formatDateToTimeOrDate(date: Date): string {
  const now = new Date();
  const isToday = date.toDateString() === now.toDateString();
  
  if (isToday) {
    return formatDateToHourMinuteTime(date);
  }

  const isThisYear = date.getFullYear() === now.getFullYear();

  if (isThisYear) {
    return formatDateToMonthShort(date.toString(), false);
  }

  return date.toLocaleDateString(
    'en-US',
    {  day: '2-digit', month: '2-digit',year: '2-digit'  },
  );
}

export const formatCurrency = (value: number, currency?: string, minimumFractionDigits?: number): string => {
  if (!currency) return (Math.round(value * 100) / 100).toFixed(2);

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  })
  .format(value);
}

export const formatNumber = (value: number): string => {
  return Intl.NumberFormat('en-US').format(value);
}
