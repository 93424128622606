import { defineStore } from 'pinia'
import axiosApi from '@/plugins/axios/axiosApi'



export type PendingAgentAction = 'Reactivate' | 'Deactivate' | 'Delete'

export type EditOrganizationPayload = Partial<OrganizationDto>

export interface OrganizationDto {
    id: string
    name: string
    logoUrl: string
}

interface State {
    currentOrganization: OrganizationDto | undefined
}

export const useOrganizationStore = defineStore('organizationStore', {
    state: (): State => ({
        currentOrganization: undefined,
    }),
    actions: {
        async getCurrentOrganization(): Promise<OrganizationDto> {
            if(!this.currentOrganization) {
                const { data } = await axiosApi.get(`session/organization`, { withCredentials: true })  
                const { dto } = data
                this.currentOrganization = dto

                return dto
            } else {
                return this.currentOrganization
            }
        },
    },
})
