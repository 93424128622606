<template>
  <button class="picker-trigger">
    <slot />
  </button>
</template>

<style scoped>
.picker-trigger {
  border-radius: 5px;
  border: 0.8px solid var(--Neutral-Colors-400, #D7D7D7);
  background: #FFF;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  color: var(--Text-Primary, #1A212B);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}
</style>
