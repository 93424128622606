const MIN_TIMER = 700;

export const useMinLoading = () => {
  const setLoading = ref(false);
  const getLoading = ref(false);
  let offTimer: ReturnType<typeof setTimeout>;

  watchEffect(() => {
    if (offTimer) {
      clearTimeout(offTimer);
    }

    if (setLoading.value) {
      getLoading.value = true;

      return;
    }

    offTimer = setTimeout(() => {
      getLoading.value = false;
    }, MIN_TIMER);
  });

  return  {
    setLoading,
    getLoading,
  };
};
