import { AnalyticsEvent } from "./analytics-events";
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

type June = undefined | {
  identify: (...params: any) => void,
  group: (...params: any) => void,
  track: (...params: any) => void,
};

export const useJuneAnalytics = () => {
  const workspaceStore = useWorkspaceStore();
  const june: June = inject('analytics');

  const identify = (userId: string, email: string, role: string) => {
    try {
      june?.identify(userId, { email, role });
    } catch(err) {
      console.error(err);
    }
  }

  const group = (workspaceId: string, name: string, avatar: string) => {
    try {
      june?.group(workspaceId, {
        name,
        avatar,
      });
    } catch(err) {
      console.error(err);
    }
  }

  const track = async (event: AnalyticsEvent) => {
    const currentWorkspace = await workspaceStore.getCurrentWorkspace();

    try {
      june?.track(
        event.type,
        event.properties,
        {
          context: {
            groupId: currentWorkspace.id,
            env: import.meta.env.VITE_ENV,
          },
        }
      )
    } catch(err) {
      console.error(err);
    }
  }

  return {
    identify,
    group,
    track,
  };
}
