export class Timer {
  private _start?: Date;
  private _remaining: number;
  private _durationTimeoutId?: ReturnType<typeof setTimeout>;
  private _callback: () => void;
  private _done = false;

  get done () {
    return this._done;
  }

  constructor(callback: () => void, ms = 0) {
    this._callback = () => {
        callback();
        this._done = true;
    };

    this._remaining = ms;
    this.resume();
  }

  pause(): Timer {
    if (this._durationTimeoutId && !this._done && this._start) {
      this._clearTimeoutRef();
      this._remaining -= new Date().getTime() - this._start.getTime();
    }

    return this;
  }

  resume(): Timer {
    if (!this._durationTimeoutId && !this._done) {
      this._start = new Date;
      this._durationTimeoutId = setTimeout(this._callback, this._remaining);
    }

    return this;
  }

  clearTimeout() {
    this._clearTimeoutRef();
    this._done = true;
  }

  private _clearTimeoutRef() {
    if (this._durationTimeoutId) {
      clearTimeout(this._durationTimeoutId);
      this._durationTimeoutId = undefined;
    }
  }
}
